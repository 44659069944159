import styled from '@emotion/styled';
import { KeyboardEvent, FocusEvent, useRef } from 'react';
import { BORDER_WIDTH } from '../../styles/borders';
import { COLOR } from '../../styles/colors';
import { SPACING } from '../../styles/spacing';
import { TYPOGRAPHY } from '../../styles/typography';

const Styled = {
  BatchTitleWrapper: styled.div`
    font-size: ${TYPOGRAPHY.heading.h1.fontSize};
    line-height: ${TYPOGRAPHY.heading.h1.lineHeight};
    font-weight: ${TYPOGRAPHY.heading.h1.fontWeight};
  `,
  BatchTitleInput: styled.span`
    border-bottom: ${BORDER_WIDTH.sm} dotted ${COLOR.blue};
    padding: ${SPACING.none};
    display: inline-block;
    word-break: break-all;
  `,
};

type BatchTitleProps = {
  value: string;
  onUpdate: (title: string) => void;
};

export default function BatchTitle({ value, onUpdate }: BatchTitleProps) {
  const batchTitleInputRef = useRef<HTMLSpanElement>(null);

  return (
    <Styled.BatchTitleWrapper>
      <Styled.BatchTitleInput
        data-dd-action-name="change batch title"
        ref={batchTitleInputRef}
        role="textbox"
        contentEditable
        suppressContentEditableWarning
        onBlur={(e: FocusEvent<HTMLSpanElement>) =>
          e.target.textContent && onUpdate(e.target.textContent)
        }
        onKeyDown={(e: KeyboardEvent<HTMLSpanElement>) => {
          if (e.code === 'Enter') {
            batchTitleInputRef.current?.blur();
            e.preventDefault();
          }
        }}
      >
        {value}
      </Styled.BatchTitleInput>
    </Styled.BatchTitleWrapper>
  );
}
