import type { CellContext } from '@tanstack/react-table';
import ProgressButton from '../../form/ProgressButton';
import { Action } from '../types';
import ButtonSet from '../../form/ButtonSet';

export type ActionsCellSettings = {
  staticActions: Action[];
};

export default function ActionsCell<TData extends Object, TValue extends unknown>({
  getValue,
  column,
  row,
}: CellContext<TData, TValue>) {
  const actions = (getValue() ?? []) as Action[];
  const cellSettings = (column.columnDef.meta?.cellSettings ?? {}) as ActionsCellSettings;
  const staticActions: Action[] = cellSettings.staticActions ?? [];
  const allActions = staticActions.concat(actions);

  // if the key "action_ids" exists in the row data, only show those actions for the row
  const availableActions = allActions.filter(
    (action) =>
      'action_ids' in row.original
        ? // @ts-ignore (typescript not smart enough to see we are checking this above)
          row.original.action_ids.some((rid) => rid === action.key)
        : true, // if there are no action_ids, filter nothing out
  );

  return (
    <ButtonSet>
      {availableActions.map(({ key, label, children, onClick, ...others }) => (
        <ProgressButton
          key={key}
          noWrap
          onClick={(event) => {
            event.stopPropagation();
            onClick?.(row, event);
          }}
          {...others}
        >
          {label || children}
        </ProgressButton>
      ))}
    </ButtonSet>
  );
}
