type PaymentSource = {
  title: string;
  paymentMethodType: string;
  brand: string;
  last4?: string | null;
};

export default function formatPaymentSourceTitle(source: PaymentSource): string {
  switch (source.paymentMethodType) {
    case 'cc':
      return `${source.brand} ending in ${source.last4}`;
    default:
      return source.title;
  }
}
