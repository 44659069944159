import { Navigate } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client';
import { batchStepQuery } from '../../../operations/queries/batchStep';
import BuyPage from './BuyPage';
import BatchRefundingPage from './BatchRefundingPage';
import BatchDetailsPage from './BatchDetailsPage';
import PageLoading from '../../loading/PageLoading';
import ShipmentDetailsPage from './ShipmentDetailsPage';
import useNavigateOrHref from '../../../hooks/useNavigateOrHref';

type ShipmentWorkflowProps = {
  batchId: string;
  shipmentId?: string;
  forwardedMailClassKey?: string;
};

export default function ShipmentWorkflow({
  batchId,
  shipmentId,
  forwardedMailClassKey,
}: ShipmentWorkflowProps) {
  const navigateOrHref = useNavigateOrHref();
  const {
    data,
    loading: stepLoading,
    error: stepError,
  } = useQuery(batchStepQuery, { variables: { id: batchId } });
  const [getStep, { loading: stepLazyLoading, error: stepLazyError }] = useLazyQuery(
    batchStepQuery,
    {
      variables: { id: batchId },
    },
  );
  const loading = stepLazyLoading || stepLoading;
  const error = stepError || stepLazyError;
  if (loading) {
    return <PageLoading />;
  }

  if (error) {
    navigateOrHref('/ship');
    return null;
  }

  // (PP-14790) Event required for current Google Tag tracking campaign
  window.dataLayer?.push({ event: 'page_view /ship/batch' });

  switch (data?.batch.step) {
    case 'NEW':
      return <Navigate to={`/upload/new/${batchId}`} />; // TODO: when the upload form page is 100% rolled out, add the page here instead of rerouting to a non-batch related URL
    case 'IMPORTED':
    case 'PURCHASING':
    case 'RATED':
      return (
        <BuyPage
          entityId={batchId}
          forwardedMailClassKey={forwardedMailClassKey}
          triggerGetStep={getStep}
        />
      );
    case 'REFUNDING':
      return <BatchRefundingPage bridgeBatchId={batchId} onRefundComplete={getStep} />;
    case 'ERROR':
    case 'BILLED':
    case 'REFUNDED':
      return shipmentId || data.batch.shipments.length === 1 ? (
        <ShipmentDetailsPage
          bridgeBatchId={batchId}
          bridgeShipmentId={shipmentId ?? data.batch.shipments[0].id}
        />
      ) : (
        <BatchDetailsPage bridgeBatchId={batchId} />
      );
    case undefined:
      return <PageLoading />;
    default:
      return null;
  }
}
