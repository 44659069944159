import { AnchorHTMLAttributes, HTMLAttributes } from 'react';
import { Link as DomRouterLink, LinkProps as ReactRouterLinkProps } from 'react-router-dom';
import styled from '@emotion/styled';
import { BORDER_WIDTH } from '../styles/borders';
import linkStyles from './linkStyles';
import environment from '../utils/environment';

export type LinkProps = ReactRouterLinkProps & {
  bridgeHref?: string; // use this when navigating to a legacy page from a bridged component
};

const BaseLink = styled.a(linkStyles);

const Styled = {
  LinkButton: styled.button`
    ${linkStyles}
    /* button-specific overrides */
    appearance: none;
    border: ${BORDER_WIDTH.none};
    padding: 0;
    background: none;
    line-height: unset;
  `,

  FakeLink: BaseLink.withComponent('span'),

  RouterLink: BaseLink.withComponent(DomRouterLink),

  // A complete clickable area without any styles at all
  LinkArea: styled.a`
    display: block;
    position: relative;
    text-decoration: underline;
    cursor: pointer;
    outline: none;
  `,
};

// Wrapper Link element that works both in bridged and unbridged environment
export default function Link({ bridgeHref, ...others }: LinkProps) {
  // it only makes sense to show an <a> component if the Link receives the bridgeHref prop.
  if (environment.isBridge() && bridgeHref) {
    return <BaseLink {...others} href={bridgeHref} />;
  }
  // otherwise, use the react router component for SPA-style linking
  return <Styled.RouterLink {...others} />;
}

export function LinkButton(props: JSX.IntrinsicElements['button']) {
  return <Styled.LinkButton type="button" {...props} />;
}

export function ExternalLink(props: AnchorHTMLAttributes<HTMLAnchorElement>) {
  return <BaseLink target="_blank" rel="noopener noreferrer" {...props} />;
}

export function MaybeExternalLink(props: AnchorHTMLAttributes<HTMLAnchorElement>) {
  const { href } = props;
  let sameOrigin = false;

  // decide whether or not a link is internal or external
  if (href) {
    try {
      // When the link we are following does not match the current origin, treat it as external
      sameOrigin = window.location.origin === new URL(href).origin;
    } catch (e) {
      // we come here if the new URL(href) is invalid.
      // check if the link appears to be a route (i.e. looks like "/foo"). if so, treat it as internal
      if (href.charAt(0) === '/') {
        sameOrigin = true;
      }
    }
  }

  const LinkComponent = sameOrigin ? BaseLink : ExternalLink;
  return <LinkComponent {...props} />;
}

export function FakeLink(props: HTMLAttributes<HTMLSpanElement>) {
  return <Styled.FakeLink role="link" tabIndex={0} {...props} />;
}

const LinkAreaRouter = Styled.LinkArea.withComponent(DomRouterLink);

export function LinkArea(props: LinkProps) {
  if (environment.isBridge()) {
    return <Styled.LinkArea {...props} />;
  }

  return <LinkAreaRouter {...props} />;
}

export function RouterLink(props: LinkProps) {
  return <Styled.RouterLink {...props} />;
}
