import { useEffect } from 'react';
import { TooltipFormatterCallbackFunction } from 'highcharts';
import { DatetimeRangeInput } from '../../../../gql/graphql';
import { useAverageCostChartLazyQuery } from '../../../../operations/queries/averageCostChart';
import { Col } from '../../../layout/Grid';
import { ReportsOverviewStyles } from '../ReportsOverviewPage';
import LineChart from '../../../charts/LineChart';
import { GREYSCALE } from '../../../../styles/colors';

type ReportsAverageCostChartProps = {
  dateFormatter: () => DatetimeRangeInput;
  dollarFormatter: TooltipFormatterCallbackFunction;
  netLabelAverage: number | undefined;
};

export default function ReportsAverageCostChart({
  dateFormatter,
  dollarFormatter,
  netLabelAverage,
}: ReportsAverageCostChartProps) {
  const [loadAverageCostChart, { data: averageCostChartData }] = useAverageCostChartLazyQuery({
    variables: {
      datetimeRange: dateFormatter(),
    },
  });

  useEffect(() => {
    loadAverageCostChart({
      variables: {
        datetimeRange: dateFormatter(),
      },
    });
  }, [dateFormatter, loadAverageCostChart]);

  return (
    <Col md={8}>
      <ReportsOverviewStyles.ShortChart
        title="Average Cost"
        loading={!averageCostChartData || netLabelAverage === undefined}
        link="View All Shipments"
        linkTo="/reports/shipment"
        fullHeight
        amount={netLabelAverage}
        labelId="reports-average-chart-headline"
        valueId="reports-average-chart-subline"
      >
        <LineChart
          data={averageCostChartData?.averageCostChart.charts ?? []}
          labels={averageCostChartData?.averageCostChart.labels ?? []}
          customOptions={{
            chart: {
              className: 'screen-chart',
            },
            tooltip: {
              formatter: dollarFormatter,
            },
          }}
        />
        <LineChart
          data={averageCostChartData?.averageCostChart.charts ?? []}
          labels={averageCostChartData?.averageCostChart.labels ?? []}
          customOptions={{
            chart: {
              className: 'print-chart',
              height: 160,
              width: 600,
              marginBottom: 45,
              marginLeft: 45,
            },
            xAxis: {
              labels: {
                rotation: -35,
                style: {
                  fontSize: '0.9em',
                },
              },
            },
            yAxis: {
              title: {
                text: '',
              },
              labels: {
                // eslint-disable-next-line no-template-curly-in-string
                format: '${value}',
                enabled: true,
                style: {
                  color: GREYSCALE.black,
                  fontSize: '0.9em',
                  fontWeight: '600',
                },
                y: 1,
              },
              gridLineWidth: 1,
            },
            tooltip: {
              formatter: dollarFormatter,
            },
          }}
        />
      </ReportsOverviewStyles.ShortChart>
    </Col>
  );
}
