import { QueryHookOptions, useLazyQuery, useQuery } from '@apollo/client';

import { gql } from '../../gql';
import { DownloadLabelsByBatchQuery, DownloadLabelsByBatchQueryVariables } from '../../gql/graphql';

export const downloadLabelsByBatchQuery = gql(/* GraphQL */ `
  query DownloadLabelsByBatchQuery($batchId: ID!, $pageLayout: PageLayout) {
    downloadLabelsByBatch(batchId: $batchId, pageLayout: $pageLayout) {
      id
      status
      fileFormat
      labelSize
      pageLayout
      url
      batch {
        id
        canInstantRefundBatch
        canPrint
        canRefund
        statusText
        shipments {
          id
          printed
          canPrint
          isShipmentRefundable
          canInstantRefundShipment
        }
      }
    }
  }
`);

export const useDownloadLabelsByBatchQuery = (
  options: QueryHookOptions<DownloadLabelsByBatchQuery, DownloadLabelsByBatchQueryVariables>,
) => useQuery(downloadLabelsByBatchQuery, options);

export const useDownloadLabelsByBatchLazyQuery = (
  options: QueryHookOptions<DownloadLabelsByBatchQuery, DownloadLabelsByBatchQueryVariables>,
) => useLazyQuery(downloadLabelsByBatchQuery, options);
