import { useRef, useState } from 'react';
import type { Row as TableRow } from '@tanstack/react-table';
import { useNavigate } from 'react-router';
import { PageContainer, Col, Row } from '../../layout/Grid';
import ConnectedDataGrid from '../../dataGrid/ConnectedDataGrid';
import PageHeader from '../../layout/PageHeader';
import { DataGridImperativeHandleRef } from '../../dataGrid/DataGrid';
import ProgressButton from '../../form/ProgressButton';
import environment from '../../../utils/environment';

function CarrierAdjustmentsPage() {
  const navigate = useNavigate();
  const [isExporting, setIsExporting] = useState(false);
  const imperativeHandleRef = useRef<DataGridImperativeHandleRef>(null);

  const viewShipment = async (row: TableRow<any>, event: any) => {
    if (event.target.tagName.toLowerCase() === 'a') return;

    if (event && (event.ctrlKey || event.metaKey)) {
      // open in new tab - won't work in web-client, only via bridge
      window.open(`${window.location.origin}/ship/shipment?id=${row.original.shipment_id}`);
      return;
    }

    if (environment.isBridge()) {
      // we need to use window for the bridged version to work correctly
      window.location.href = `${window.location.origin}/ship/shipment?id=${row.original.shipment_id}`;
    } else {
      navigate(`/batch/${row.original.batch_id}/shipment/${row.original.shipment_id}`);
    }
  };

  const getInitialRunId = () => {
    const reconciliationRunId = new URLSearchParams(window.location.search).get('run');

    if (!reconciliationRunId) {
      return [];
    }

    return [{ id: 'reconciliation_run_id', value: [reconciliationRunId] }];
  };

  return (
    <PageContainer>
      <PageHeader title="Carrier Adjustment Report" />
      <Row>
        <Col md={12}>
          <ConnectedDataGrid
            imperativeHandleRef={imperativeHandleRef}
            queryName="carrierAdjustmentReport"
            onRowClick={viewShipment}
            rowActionHandlers={{
              view: viewShipment,
            }}
            rightSideSlot={
              <Col xs={12} md={3} spaceBelow>
                <ProgressButton
                  progress={isExporting}
                  disabled={isExporting}
                  fullWidth
                  size="medium"
                  variant="info"
                  onClick={async () => {
                    setIsExporting(true);
                    await imperativeHandleRef.current?.exportData();
                    setIsExporting(false);
                  }}
                >
                  Export
                </ProgressButton>
              </Col>
            }
            initialColumnFilters={getInitialRunId()}
          />
        </Col>
      </Row>
    </PageContainer>
  );
}

export default CarrierAdjustmentsPage;
