import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import styled from '@emotion/styled';

import { ApolloError } from '@apollo/client';
import { Col, PageContainer, Row } from '../../layout/Grid';
import { useGetReceiptQuery } from '../../../operations/queries/receipt';
import {
  StyledSimpleDataGridContainer,
  StyledSimpleDataGridTable,
} from '../../dataGrid/SimpleDataGrid';
import { Payment } from '../../../gql/graphql';
import PirateShipLogoFull from '../../../images/pirateshipLogoFull.svg';
import Link, { ExternalLink } from '../../Link';
import { TYPOGRAPHY } from '../../../styles/typography';
import { SPACING } from '../../../styles/spacing';
import { COLOR, GREYSCALE } from '../../../styles/colors';
import Shimmer from '../../loading/Shimmer';
import formatAddress, { Address } from '../../../utils/formatAddress';
import formatCurrency from '../../../utils/formatCurrency';
import { DATE_FORMAT } from '../../../constants';
import { BORDER_WIDTH } from '../../../styles/borders';
import useDateInUserTimezone from '../../../hooks/useDateInUserTimezone';
import Alert from '../../Alert';
import { CountryMap } from '../../../utils/createCountryMap';
import convertBbcode from '../../../utils/convertBbcode';

type ReceiptPageProps = {
  testId?: string;
};
type ReceiptTableRowType = {
  amount: string;
  balance: string | null;
  createdAt: string | null;
  title: string | null;
  transactionType: string;
  pymntId: string;
  batchId: string;
  shipmentId: string;
  reconciliationRunId: string;
};

type ReceiptReversal = {
  amount: number;
  action: {
    createdAt: string;
    newPaymentStatus: string;
  };
  coveringAccountingTransaction: {
    paymentId: string;
  } | null;
};

const TRANSACTION_TYPE_TRANSLATIONS = new Map<string, string>([
  ['print', 'Label'],
  ['return', 'Return Label'],
  ['payment', 'Payment'],
  ['payment_refund', 'Payment Refunded'],
  ['payment_failed', 'Payment Failed'],
  ['payment_failed_fee', 'Payment Failed Fee'],
  ['refund', 'Refund'],
  ['reconciliation', 'Carrier Adjustment'],
  ['credit', 'Credit'],
  ['manual_adjustment', 'Adjustment'],
  ['payment_fee', 'Fee'],
  ['pickuprequest', 'Pickup'],
  ['pickuprequest_refund', 'Pickup Refund'],
  ['reconciliation_refund', 'Carrier Adjustment Refund'],
]);

const PAYMENT_STATUS_TRANSLATIONS = new Map<string, string>([
  ['new', 'New'],
  ['pending', 'Pending'],
  ['charged', 'Charged'],
  ['failed_charge', 'Failed'],
  ['authorized', 'Authorized'],
  ['canceled_authorization', 'Canceled Authorization'],
  ['failed_authorization', 'Failed Authorization'],
  ['failed_capture', 'Failed Capture'],
  ['disputed', 'Disputed'],
  ['canceled', 'Canceled'],
  ['refunded', 'Refunded'],
  ['partially_refunded', 'Partially refunded'],
  ['pending_consolidation', 'Pending Consolidation'],
  ['consolidated', 'Consolidated'],
  ['reconciliation_refund', 'Carrier Adjustment Refund'],
]);

const usOnlyCountryMap: CountryMap = {
  US: { name: 'United States', countryCode: 'US', id: '1' },
};

const Styled = {
  PageContainer: styled(PageContainer)`
    p {
      // match web-client p styles to those in bridge/legacy
      margin-block: ${SPACING.none};
    }
    @media print {
      @page {
        size: letter portrait;
        margin: 0.4in;
      }
    }
  `,
  THead: styled('thead')`
    th {
      background-color: ${GREYSCALE.white} !important;
    }
  `,
  TBody: styled('tbody')`
    tr:hover td {
      color: ${GREYSCALE.white};
      a,
      span {
        color: ${GREYSCALE.white};
      }
      background-color: ${GREYSCALE.black} !important;
    }
    .payment {
      font-weight: ${TYPOGRAPHY.fontWeight.medium};
      background-color: ${GREYSCALE.grey10};
      td {
        border-width: ${BORDER_WIDTH.sm} ${BORDER_WIDTH.xs} ${BORDER_WIDTH.sm} ${BORDER_WIDTH.none};
      }
    }
  `,
  ReceiptStatus: styled('span')<{ type?: string }>`
    font-size: ${TYPOGRAPHY.fontSize.xxxxl};
    font-weight: ${TYPOGRAPHY.fontWeight.bold};
    text-transform: uppercase;
    color: ${({ type }) => {
      switch (type) {
        case 'error':
          return COLOR.darkRed;
        case 'success':
          return COLOR.green;
        default:
          return 'inherit';
      }
    }};
  `,
  ReceiptError: styled('p')`
    color: ${COLOR.darkRed};
    text-align: right;
    text-wrap: balance;
  `,
  CurrencyLabel: styled('span')<{ type?: string }>`
    text-decoration: ${({ type }) => {
      switch (type) {
        case 'error':
          return 'line-through';
        default:
          return 'inherit';
      }
    }};
    color: ${({ type }) => {
      switch (type) {
        case 'negative':
        case 'error':
          return COLOR.darkRed;
        default:
          return 'inherit';
      }
    }};
  `,
  FullLogo: styled('img')`
    margin-bottom: ${SPACING.xxl};
    max-width: 100%;
    @media print {
      margin-bottom: ${SPACING.none};
      width: 200px;
    }
  `,
  ReceiptHeader: styled(Row)`
    background-color: ${GREYSCALE.grey10};
    border: ${BORDER_WIDTH.sm} solid;
    border-color: ${GREYSCALE.grey30} transparent;
    margin-inline: ${SPACING.none} !important;
    padding: ${SPACING.lg} ${SPACING.none};
    font-weight: ${TYPOGRAPHY.fontWeight.medium};
  `,
  AddressBlock: styled('div')`
    white-space: pre-line;
    margin-block: ${SPACING.xl};
    @media print {
      margin-block: ${SPACING.sm};
    }
  `,
  Reversal: styled('p')`
    color: ${COLOR.darkRed};
    font-size: ${TYPOGRAPHY.fontSize.sm};
  `,
  RightAlignedCol: styled(Col)`
    text-align: right;
  `,
  DimText: styled('p')`
    color: ${GREYSCALE.grey50};
    font-size: ${TYPOGRAPHY.fontSize.sm};
  `,
  RightAlign: styled('td')`
    text-align: right;
  `,
};

function ReceiptPage({ testId }: ReceiptPageProps) {
  const { formatDate } = useDateInUserTimezone();

  const formatDateWithCustomFormat = (dateString: string) =>
    formatDate(
      'UTC',
      dateString,
      `${DATE_FORMAT.dayOfWeek}, ${DATE_FORMAT.usDate} ${DATE_FORMAT.time12NoLeadingZero} ${DATE_FORMAT.timezone}`,
    );
  const { paymentId } = useParams<'paymentId'>();

  const id = paymentId ?? testId ?? '';
  const { data, loading, error } = useGetReceiptQuery({
    variables: {
      id,
    },
    context: { errorHandled: true },
  });

  // price string | number because of inconsistent db types
  const getFormattedPrice = (price: string | number, typeOverride?: string) => {
    if (typeof price !== 'number') {
      price = parseFloat(price); // eslint-disable-line
    }
    let type = price >= 0 ? 'positive' : 'negative';
    if (typeof typeOverride !== 'undefined') {
      type = typeOverride;
    }
    return <Styled.CurrencyLabel type={type}>{formatCurrency(price)}</Styled.CurrencyLabel>;
  };

  const getFormattedReceiptLabel = (
    isPaymentSuccessful: boolean,
    isCharged: boolean,
    payment: Payment,
  ) => {
    let label: string;
    let type: string;

    if (!isPaymentSuccessful) {
      label = 'Failed';
      type = 'error';
    } else if (isCharged) {
      label = 'Paid';
      type = 'success';
    } else {
      label = PAYMENT_STATUS_TRANSLATIONS.get(payment.status) || payment.status;
      type = 'error';
    }

    return (
      <Styled.ReceiptStatus type={type} data-testid="status">
        {label}
      </Styled.ReceiptStatus>
    );
  };

  const renderLink = (url: string, text: string, bridgeurl?: string) => {
    if (!url || !text) {
      return null;
    }

    return (
      <>
        {' '}
        <Link to={url} bridgeHref={bridgeurl ?? url}>
          {text}
        </Link>
      </>
    );
  };

  const renderReversal = (reversal: ReceiptReversal) => (
    <Styled.Reversal data-testid="reversal">
      {`${PAYMENT_STATUS_TRANSLATIONS.get(reversal.action.newPaymentStatus)} (${formatCurrency(
        reversal.amount,
      )}) on ${formatDateWithCustomFormat(reversal.action.createdAt)}`}
      {reversal.coveringAccountingTransaction?.paymentId &&
        renderLink(
          `/reports/receipt/${reversal.coveringAccountingTransaction.paymentId}`,
          `View the related receipt #${reversal.coveringAccountingTransaction.paymentId}`,
        )}
    </Styled.Reversal>
  );

  const billingAddress = data?.receipt.billingAddress;
  const payment = data?.receipt.payment;

  const transactions: ReceiptTableRowType[] =
    useMemo(
      () =>
        data?.receipt.transactions.map((transaction) => {
          const {
            paymentId: pymntId,
            batchId,
            shipmentId,
            reconciliationRunId,
            createdAt,
            transactionType,
            title,
            amount,
            balance,
          } = transaction;

          return {
            pymntId,
            batchId,
            shipmentId,
            reconciliationRunId,
            createdAt,
            transactionType,
            title,
            amount,
            balance,
          };
        }),
      [data?.receipt.transactions],
    ) || [];

  const descriptionLinks = (props: ReceiptTableRowType) => {
    if (props.pymntId !== '0') {
      if (props.pymntId === id) return props.title;
      return renderLink(
        `/reports/receipt/${props.pymntId}`,
        props.title || '',
        `/reports/receipt?id=${props.pymntId}`,
      );
    }
    if (props.reconciliationRunId !== '0')
      return renderLink(
        `/reports/carrieradjustment/${props.reconciliationRunId}`,
        props.title || '',
        `/reports/carrieradjustment?run=${props.reconciliationRunId}`,
      );
    if (props.shipmentId !== '0')
      return renderLink(
        `/batch/${props.batchId}/shipment/${props.shipmentId}`,
        props.title || '',
        `/ship/shipment?id=${props.shipmentId}`,
      );
    if (props.batchId !== '0')
      return renderLink(
        `/batch/${props.batchId}`,
        props.title || '',
        `/ship/batch?id=${props.batchId}`,
      );
    return null;
  };

  if (error && error instanceof ApolloError) {
    const paymentNotFoundError = error.graphQLErrors.find(
      ({ extensions }) => extensions?.failureReason === 'PAYMENT_NOT_FOUND',
    );

    if (paymentNotFoundError) {
      return (
        <Styled.PageContainer>
          <Alert variant="danger">
            <strong>No payment found for the provided id</strong>
          </Alert>
        </Styled.PageContainer>
      );
    }

    return (
      <Styled.PageContainer>
        <Alert variant="danger">
          <strong>{error.message}</strong>
        </Alert>
      </Styled.PageContainer>
    );
  }

  return (
    <Styled.PageContainer>
      <Row spaceBelow>
        <Col>
          <Styled.FullLogo src={PirateShipLogoFull} alt="Pirate Ship Logo" width="220" />
        </Col>
        <Styled.RightAlignedCol>
          {/* Status Text + Error Message */}
          {loading && <Shimmer.Rectangle />}
          {!loading &&
            getFormattedReceiptLabel(
              data?.receipt.paymentSuccessful as boolean,
              data?.receipt.isCharged as boolean,
              data?.receipt.payment as Payment,
            )}
          {data?.receipt.payment.chargeError && !data.receipt.paymentSuccessful && (
            <Styled.ReceiptError>
              {convertBbcode(data?.receipt.payment.chargeError)}
            </Styled.ReceiptError>
          )}
        </Styled.RightAlignedCol>
      </Row>

      <Row>
        <Col xs={6} spaceBelow>
          <h1>{`Payment Receipt #${id}`}</h1>
          {loading && <Shimmer.Rectangle />}
          {!loading && !!payment?.createdAt && (
            <Styled.DimText>{formatDateWithCustomFormat(payment.createdAt)}</Styled.DimText>
          )}
        </Col>
      </Row>

      {loading && <Shimmer.Rectangle />}
      {!loading && !!data?.receipt.reversals[0] && (
        <Row>
          <Col>{data?.receipt.reversals.map((reversal) => renderReversal(reversal))}</Col>
        </Row>
      )}

      <Row>
        <Col spaceBelow>
          {loading && <Shimmer.Rectangle height={100} />}
          {!loading && (
            <Styled.AddressBlock data-testid="address">
              {formatAddress(
                {
                  fullName: billingAddress?.fullName || '',
                  company: billingAddress?.company || '',
                  address1: billingAddress?.address1 || '',
                  address2: billingAddress?.address2 || '',
                  city: billingAddress?.city || '',
                  regionCode: billingAddress?.regionCode || '',
                  postcode: billingAddress?.postcode || '',
                  zip4: billingAddress?.zip4 || '',
                } as Address,
                usOnlyCountryMap,
              )}
            </Styled.AddressBlock>
          )}
        </Col>
      </Row>

      {loading && <Shimmer.Rectangle />}
      {!loading && (
        <Styled.ReceiptHeader spaceBelow>
          <Col>{payment?.title}</Col>
          <Styled.RightAlignedCol>
            {getFormattedPrice(
              payment?.total || '',
              data?.receipt.payment.status === 'partially_refunded' ? 'error' : undefined,
            )}
            {data?.receipt.payment.status === 'partially_refunded' && (
              <span style={{ marginLeft: SPACING.md }}>
                {getFormattedPrice(
                  parseFloat(data.receipt.payment.total) - data.receipt.payment.refundTotal,
                )}
              </span>
            )}
          </Styled.RightAlignedCol>
        </Styled.ReceiptHeader>
      )}

      <Row>
        <Col spaceBelow>
          {loading && <Shimmer.Rectangle />}
          {!loading && !!transactions && (
            <StyledSimpleDataGridContainer showPagination={false} hasError={false}>
              <StyledSimpleDataGridTable isHorizontallyScrolling={false}>
                <Styled.THead>
                  <tr>
                    <th>Date</th>
                    <th>Type</th>
                    <th>Description</th>
                    <th>Total</th>
                    <th>Balance</th>
                  </tr>
                </Styled.THead>
                <Styled.TBody>
                  <tr>
                    <Styled.RightAlign colSpan={4}>Starting account balance</Styled.RightAlign>
                    <Styled.RightAlign>
                      {getFormattedPrice(data?.receipt.startBalance as number)}
                    </Styled.RightAlign>
                  </tr>
                  {transactions.map((transaction) => (
                    <tr className={transaction.transactionType}>
                      <td>{formatDateWithCustomFormat(transaction.createdAt ?? '')}</td>
                      <td>{TRANSACTION_TYPE_TRANSLATIONS.get(transaction.transactionType)}</td>
                      <td>{descriptionLinks(transaction)}</td>
                      <Styled.RightAlign>{getFormattedPrice(transaction.amount)}</Styled.RightAlign>
                      <Styled.RightAlign>
                        {transaction.balance && getFormattedPrice(transaction.balance)}
                      </Styled.RightAlign>
                    </tr>
                  ))}
                  <tr>
                    <Styled.RightAlign colSpan={4}>Ending account balance</Styled.RightAlign>
                    <Styled.RightAlign>
                      {getFormattedPrice(data?.receipt.endBalance as number)}
                    </Styled.RightAlign>
                  </tr>
                </Styled.TBody>
              </StyledSimpleDataGridTable>
            </StyledSimpleDataGridContainer>
          )}
        </Col>
      </Row>

      <Row>
        <Col spaceBelow>
          To see your entire transaction history,{' '}
          <Link to="/reports" bridgeHref="/reports#form-head">
            visit your reports page
          </Link>
          .
          <br />
          <br />
          ARRRRrrrrr... we thank y&apos;ee f&apos;er y&apos;er business!
        </Col>
      </Row>

      <Row>
        <Col>
          <Styled.DimText>
            Pirate Ship LLC - PO Box 9149, Jackson, WY 83002 -{' '}
            <ExternalLink href="https://pirateship.com/">pirateship.com</ExternalLink>
          </Styled.DimText>
        </Col>
      </Row>
    </Styled.PageContainer>
  );
}

export default ReceiptPage;
