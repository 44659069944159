import { PDFObject } from 'react-pdfobject';
import useWindowSize from '../../hooks/useWindowSize';
import { Col } from '../layout/Grid';
import { FileFormat } from '../../gql/graphql';

type PrintPreviewProps = {
  shipmentLabelFileFormat: FileFormat;
  url: string;
};

export default function PDFPreview({ shipmentLabelFileFormat, url }: PrintPreviewProps) {
  const [, windowHeight] = useWindowSize();

  if (shipmentLabelFileFormat === 'ZPL') {
    return null;
  }

  // default UI from PDFObject with force to download link
  const fallBackUrl = `<p>This browser does not support inline PDFs. Please download the PDF to view it: <a href="${url.replace(
    'force/0',
    'force/1',
  )}">Download PDF</a></p>`;
  return (
    <Col md={12} spaceBelow data-testid="pdf-object">
      <PDFObject url={url} fallbackLink={fallBackUrl} height={`${windowHeight / 2}px`} />
    </Col>
  );
}
