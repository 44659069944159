import { useMemo, useState } from 'react';
import { useIntercom } from 'react-intercom-hook';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import Modal, { ModalButtonBar } from './Modal';
import { Row, Col } from '../layout/Grid';
import { FakeLink } from '../Link';
import PageSubtitle from '../layout/PageSubtitle';
import BillingAddressSubform from '../subforms/BillingAddressSubform';
import { billingAddressValidationSchema } from '../subforms/billingAddressSubformUtls';
import { useBillingAddressQuery } from '../../operations/queries/billingAddress';
import { useBillingAddressMutation } from '../../operations/mutations/updateBillingAddress';
import Loading from '../loading/PageLoading';
import Alert from '../Alert';
import { UpdateBillingAddressMutationVariables } from '../../gql/graphql';
import useCurrentUser from '../../hooks/useCurrentUser';
import { useCreateUspsMerchantAccountMutation } from '../../operations/mutations/createUspsMerchant';
import withIntercomLink from '../../utils/withIntercomLink';
import getPostcodeAndZip4 from '../../utils/getPostcodeAndZip4';

type UspsAddressCorrectionModalProps = {
  open: boolean;
  onCancel: () => void;
  onAccountCreated: () => void;
};

function UspsAddressCorrectionModal({
  open,
  onCancel,
  onAccountCreated,
}: UspsAddressCorrectionModalProps) {
  const [createUspsMerchantAccount, { loading: uspsMerchantCreating, data: merchantData }] =
    useCreateUspsMerchantAccountMutation();
  const { showNewMessages } = useIntercom();
  const [currentUser] = useCurrentUser();
  const { data: billingAddressData, loading: billingAddressLoading } = useBillingAddressQuery();
  const [updateBillingAddress, { loading: billingAddressUpdating }] = useBillingAddressMutation();
  const [isAddressRetry, setIsAddressRetry] = useState(false);

  const handleSubmit = async ({ billingAddress }: UpdateBillingAddressMutationVariables) => {
    const { postcode, zip4 } = getPostcodeAndZip4(billingAddress.postcode);

    await updateBillingAddress({
      variables: {
        billingAddress: {
          fullName: billingAddress.fullName,
          company: billingAddress.company,
          address1: billingAddress.address1,
          address2: billingAddress.address2,
          postcode,
          zip4,
          regionCode: billingAddress.regionCode,
          countryCode: billingAddress.countryCode,
          city: billingAddress.city,
          phone: billingAddress.phone,
        },
      },
    });

    const { data: createdData } = await createUspsMerchantAccount();

    if (createdData?.createUspsMerchantAccount.status === 'ok') {
      onAccountCreated();
    }

    setIsAddressRetry(true);
  };

  const merchantCreationError = merchantData?.createUspsMerchantAccount.error;
  const errorCategory = merchantData?.createUspsMerchantAccount.errorCategory;
  const hasAddressError = errorCategory === 'address';

  /**
   * On address errors, we only want to show the address modal and hide the error from the user to make it seem like everyting is ok
   * We show the error on retry to make it clear to the user that the changed address was not accepted and it is not just a broken
   * modal that does not do anything
   * If the error is not an address error, we want to show the error message, so it is clear that there could be something else
   * going wrong and it could be that changing the address does not help
   */
  const showAddressModalError = merchantCreationError && (isAddressRetry || !hasAddressError);

  const validationSchema = useMemo(() => billingAddressValidationSchema('usps'), []);

  return (
    <Modal
      width={800}
      open={open}
      onClose={onCancel}
      title="USPS needs more information to continue"
      closable={false}
    >
      {!billingAddressData ? (
        <Loading height="20vh" />
      ) : (
        <>
          {showAddressModalError && (
            <Alert variant="danger">
              {withIntercomLink(merchantCreationError, () =>
                showNewMessages('I need help updating my physical address for USPS.'),
              )}
            </Alert>
          )}
          <Formik
            enableReinitialize
            validationSchema={yup.object({ billingAddress: validationSchema })}
            initialValues={{
              billingAddress: {
                ...billingAddressData.company.address,
                email: currentUser?.email ?? '',
              },
            }}
            onSubmit={handleSubmit}
          >
            <Form>
              <Row>
                <Col md={12}>
                  This is just an address USPS requires to be on record, and will not be made public
                  or shared with any of your recipients, and will not change the Ship From Address
                  that&apos;s printed on the label.
                </Col>
                <Col md={12}>
                  <PageSubtitle>Update your Physical Address and try again:</PageSubtitle>
                </Col>
                <Col md={12}>
                  <BillingAddressSubform namespace="billingAddress" carrier="usps" />
                </Col>
                <Col md={12}>
                  If you need any help just{' '}
                  <FakeLink
                    onClick={() => {
                      showNewMessages('I need help updating my physical address for USPS.');
                      onCancel();
                    }}
                  >
                    chat with our support
                  </FakeLink>
                  !
                </Col>
                <Col md={12}>
                  <ModalButtonBar
                    cancelText="Cancel"
                    cancelOnClick={onCancel}
                    buttonText={`${isAddressRetry ? 'Try again' : 'Submit'}`}
                    buttonVariant="success"
                    buttonType="submit"
                    buttonSize="large"
                    buttonProgress={billingAddressUpdating || uspsMerchantCreating}
                    buttonDisabled={
                      billingAddressUpdating || billingAddressLoading || uspsMerchantCreating
                    }
                  />
                </Col>
              </Row>
            </Form>
          </Formik>
        </>
      )}
    </Modal>
  );
}
export default UspsAddressCorrectionModal;
