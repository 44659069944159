import { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import Modal from '../Modal';
import useWindowSize from '../../../hooks/useWindowSize';
import FlashMessages from '../../FlashMessages';
import { flashMessagesVar } from '../../../apollo/cache';
import { clearFlashMessages } from '../../../apollo/cache/flashMessages';
import { useDownloadLabelsByBatchLazyQuery } from '../../../operations/queries/downloadLabelsByBatch';
import { Container, Row, Col } from '../../layout/Grid';
import PageLoading from '../../loading/PageLoading';
import PageLayoutSelect from '../../form/PageLayoutSelect';
import ProgressButton from '../../form/ProgressButton';
import ShareLink from '../../ShareLink';
import PrintPreview from '../../print/PDFPreview';
import downloadUrl from '../../../utils/downloadUrl';
import { useCreateScanformByBatchMutation } from '../../../operations/mutations/createScanformByBatch';
import { useShareDownloadUrlByBatchLazyQuery } from '../../../operations/queries/shareDownloadUrlByBatch';
import ScanformButton from '../../form/ScanformButton';
import { PageLayout } from '../../../gql/graphql';

type BatchPrintModalProps = {
  open: boolean;
  onClose: () => void;
  batchId: string;
  canCreateScanform: boolean;
  scanformUrl: string | null;
};

export default function BatchPrintModal({
  open,
  onClose,
  batchId,
  canCreateScanform,
  scanformUrl,
}: BatchPrintModalProps) {
  const [, windowHeight] = useWindowSize();
  const [polling, setPolling] = useState(false);
  const [pageLayout, setPageLayout] = useState<PageLayout>();
  const [createScanfrom, { loading: creatingScanfrom }] = useCreateScanformByBatchMutation();

  const [createShareLink, { data: shareLinkData }] = useShareDownloadUrlByBatchLazyQuery({
    variables: { batchId },
  });
  const [downloadLabelsByBatch, { data, loading, error, startPolling, stopPolling }] =
    // with lazy queries, we are required to provide default options, even if we always overwrite them in the function call
    // see here: https://www.apollographql.com/docs/react/data/queries/#manual-execution-with-uselazyquery
    useDownloadLabelsByBatchLazyQuery({
      variables: {
        batchId,
      },
    });

  useEffect(() => {
    if (open) {
      setPolling(true);
    } else {
      stopPolling();
    }
  }, [open, stopPolling]);

  // once we get the data, we initialise the filePageLayout
  useEffect(() => {
    if (data && !pageLayout) {
      setPageLayout(data.downloadLabelsByBatch.pageLayout ?? undefined);
    }
  }, [data, pageLayout]);

  useEffect(() => {
    if (polling) {
      downloadLabelsByBatch({
        variables: {
          batchId,
          pageLayout,
        },
      });
      startPolling(1000);
    }
    if (
      error ||
      data?.downloadLabelsByBatch?.status === 'FINISHED' ||
      data?.downloadLabelsByBatch?.status === 'ERROR'
    ) {
      setPolling(false);
      stopPolling();
    }
  }, [batchId, data, downloadLabelsByBatch, error, pageLayout, polling, startPolling, stopPolling]);

  const flashMessages = useReactiveVar(flashMessagesVar);

  const onModalClose = () => {
    clearFlashMessages();
    onClose?.();
  };

  const renderPreview = () => {
    if (flashMessages.length) {
      return null; // TODO: render flash messages
    }
    if (
      !loading &&
      pageLayout &&
      // correct status to show the print preview
      data?.downloadLabelsByBatch.fileFormat &&
      data.downloadLabelsByBatch.url &&
      data?.downloadLabelsByBatch?.status === 'FINISHED'
    ) {
      const { fileFormat, url } = data.downloadLabelsByBatch;

      return (
        <>
          <Row>
            <Col md={12}>
              <PageLayoutSelect
                pageLayout={pageLayout}
                fileFormat={fileFormat}
                onPageLayoutChange={(layout) => {
                  setPageLayout(layout);
                  setPolling(true);
                }}
              />
            </Col>
            <PrintPreview shipmentLabelFileFormat={fileFormat} url={url} />
          </Row>
          <Row>
            <Col xs={12} sm={6} spaceBelow>
              <ScanformButton
                canCreateScanform={canCreateScanform}
                scanformUrl={scanformUrl}
                loading={creatingScanfrom}
                onCreateScanfrorm={() => {
                  createScanfrom({
                    variables: {
                      batchId,
                    },
                  });
                }}
              />
            </Col>
            <Col xs={12} sm={canCreateScanform || scanformUrl ? 6 : 12} spaceBelow>
              <ProgressButton
                variant="success"
                fullWidth
                data-testid="download-label-button"
                disabled={false}
                progress={false}
                onClick={(event) => {
                  event.stopPropagation();
                  downloadUrl(url);
                }}
              >
                Download Labels
              </ProgressButton>
            </Col>

            <Col xl={12}>
              <ShareLink
                onClick={() => createShareLink()}
                url={shareLinkData?.shareDownloadUrlByBatch}
              />
            </Col>
          </Row>
        </>
      );
    }
    return <PageLoading height={`${windowHeight / 2}px`} color="white" />;
  };

  return (
    <Modal theme="dark" open={open} width={900} onClose={onModalClose}>
      <Container noGutter>
        <Row>
          <Col md={12}>
            <FlashMessages />
          </Col>
        </Row>
        {renderPreview()}
      </Container>
    </Modal>
  );
}
