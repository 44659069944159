import { useSuspenseQuery } from '@apollo/client';
import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import * as yup from 'yup';
import { NotificationsSettings } from '../../assets/data/notificationSettings';
import { useUpdateNotificationsSettingsMutation } from '../../operations/mutations/updateNotificationsSettings';
import { accountSettingsQuery } from '../../operations/queries/accountSettings';
import Link from '../Link';
import FormControl from '../form/FormControl';
import Toggle from '../form/Toggle';
import { Col, Row } from '../layout/Grid';
import PageSubtitle from '../layout/PageSubtitle';

const initialNotificationsSettings: NotificationsSettings = {
  carrierAdjustmentsNotificationEnabled: false,
  carrierAdjustmentDisputesEnabled: false,
  paymentReceiptsNotificationEnabled: false,
  paymentFailuresNotificationEnabled: false,
  newLoginNotificationEnabled: false,
  newFeaturesAnnouncementsNotificationEnabled: false,
};

const notificationsInitValidationSchema = () =>
  yup.object<NotificationsSettings>({
    carrierAdjustmentsNotificationEnabled: yup.boolean().defined(),
    carrierAdjustmentDisputesEnabled: yup.boolean().defined(),
    paymentReceiptsNotificationEnabled: yup.boolean().defined(),
    paymentFailuresNotificationEnabled: yup.boolean().defined(),
    newLoginNotificationEnabled: yup.boolean().defined(),
    newFeaturesAnnouncementsNotificationEnabled: yup.boolean().defined(),
  });

export default function EmailNotificationsForm() {
  const notificationsValidationSchema = useMemo(notificationsInitValidationSchema, []);
  const { data: accountSettingsData } = useSuspenseQuery(accountSettingsQuery);
  const [updateNotificationsSettings] = useUpdateNotificationsSettingsMutation();

  const CarrierAdjustmentsSublabel =
    "Get an email whenever you're charged (or credited) because your package was different than expected.";

  const PaymentReceiptsSublabel =
    'Get an emailed copy of your receipt every time your payment method is charged.';

  const PaymentFailuresSublabel =
    'Get an email notification any time your payment method is declined.';

  const NewLoginNotificationsSublabel =
    'Get an email if your account is accessed from a new device or new location.';

  const CarrierAdjustmentDisputesSublabel =
    'Get an email when your adjustment disputes are approved or denied by the carrier.';

  return (
    <Formik
      initialValues={accountSettingsData.notificationsSettings || initialNotificationsSettings}
      enableReinitialize
      validateOnChange
      validationSchema={notificationsValidationSchema}
      validate={async (values: NotificationsSettings) => {
        await updateNotificationsSettings({
          refetchQueries: [accountSettingsQuery],
          variables: {
            carrierAdjustmentsNotificationEnabled: values.carrierAdjustmentsNotificationEnabled,
            paymentReceiptsNotificationEnabled: values.paymentReceiptsNotificationEnabled,
            paymentFailuresNotificationEnabled: values.paymentFailuresNotificationEnabled,
            newLoginNotificationEnabled: values.newLoginNotificationEnabled,
            carrierAdjustmentDisputesEnabled: values.carrierAdjustmentDisputesEnabled,
            newFeaturesAnnouncementsNotificationEnabled:
              values.newFeaturesAnnouncementsNotificationEnabled,
          },
        });
      }}
      onSubmit={() => {
        // nothing to do
      }}
    >
      <Form>
        <Row>
          <Col md={12}>
            <PageSubtitle>Notifications</PageSubtitle>
          </Col>
          <Col spaceBelow md={12}>
            Choose which emails you want to receive from Pirate Ship. Don&lsquo;t worry, your email
            address is <strong>never</strong> sold or shared, as detailed in our{' '}
            <Link to="/privacy" bridgeHref="/privacy">
              Privacy Policy
            </Link>{' '}
            &#x1F44D;
          </Col>
          <Col spaceBelow md={12}>
            <FormControl
              name="carrierAdjustmentsNotificationEnabled"
              as={Toggle}
              type="checkbox"
              label="Carrier Adjustments"
              sublabel={CarrierAdjustmentsSublabel}
            />
          </Col>
          <Col spaceBelow md={12}>
            <FormControl
              name="carrierAdjustmentDisputesEnabled"
              as={Toggle}
              type="checkbox"
              label="Carrier Adjustment Disputes"
              sublabel={CarrierAdjustmentDisputesSublabel}
            />
          </Col>
          <Col spaceBelow md={12}>
            <FormControl
              name="paymentReceiptsNotificationEnabled"
              as={Toggle}
              type="checkbox"
              label="Payment Receipts"
              sublabel={PaymentReceiptsSublabel}
            />
          </Col>
          <Col spaceBelow md={12}>
            <FormControl
              name="paymentFailuresNotificationEnabled"
              as={Toggle}
              type="checkbox"
              label="Payment Failures"
              sublabel={PaymentFailuresSublabel}
            />
          </Col>
          <Col spaceBelow md={12}>
            <FormControl
              name="newLoginNotificationEnabled"
              as={Toggle}
              type="checkbox"
              label="New Login Notifications"
              sublabel={NewLoginNotificationsSublabel}
            />
          </Col>
        </Row>
      </Form>
    </Formik>
  );
}
