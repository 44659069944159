import { useMemo, useRef } from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router';
import { Row, Col } from '../../layout/Grid';
import FormControl from '../../form/FormControl';
import TextField from '../../form/TextField';
import InputGroup from '../../form/InputGroup';
import { useResetPasswordMutation } from '../../../operations/mutations/resetPassword';
import { setFlashMessage } from '../../../apollo/cache/flashMessages';
import ProgressButton from '../../form/ProgressButton';
import CenteredPageHeader from '../../layout/CenteredPageHeader';
import LandlubberContent from '../../layout/LandlubberContent';
import environment from '../../../utils/environment';

type ChangeValues = {
  password_new: string;
  password_repeat: string;
};

const changeInitialValues: ChangeValues = {
  password_new: '',
  password_repeat: '',
};

const initChangeValidationSchema = () =>
  yup.object<ChangeValues>({
    password_new: yup
      .string()
      .required()
      .min(12, 'This value is too short. It should have 12 characters or more.')
      .max(200, 'This value is too long. It should have 200 characters or less.'),
    password_repeat: yup
      .string()
      .required()
      .test('passwords-match', 'Passwords do not match', function passwordsMatch(value) {
        return this.parent.password_new === value;
      }),
  });

function useHash() {
  const { hash } = useParams<'hash'>();

  if (environment.isBridge()) {
    const searchParams = new URLSearchParams(window.location.search);

    if (searchParams.has('hash')) {
      return searchParams.get('hash');
    }

    const [hashValue] = window.location.pathname.split('/').reverse();

    return hashValue;
  }

  return hash;
}

export default function ResetPasswordPage() {
  const [resetPassword, { loading: resetting }] = useResetPasswordMutation();
  const form = useRef<HTMLFormElement | null>(null);
  const hash = useHash();
  const navigate = useNavigate();
  const changeValidationSchema = useMemo(initChangeValidationSchema, []);

  return (
    <LandlubberContent>
      <CenteredPageHeader title="Create a new password" />
      <Formik
        initialValues={changeInitialValues}
        validationSchema={changeValidationSchema}
        onSubmit={async ({ password_new }) => {
          if (environment.isBridge()) {
            form.current?.submit();
            return;
          }
          await resetPassword({
            variables: {
              resetToken: hash!,
              newPassword: password_new,
            },
          });

          // Redirect to login page
          navigate('/');

          // Show flash message
          setFlashMessage('Password set', 'success');
        }}
      >
        <Form
          ref={form}
          {...(environment.isBridge() && {
            method: 'POST',
            action: '/password/update',
          })}
        >
          {environment.isBridge() && <input type="hidden" name="hash" value={hash ?? ''} />}
          <Row>
            <Col spaceBelow md={12}>
              <InputGroup prefixIcon="asterisk">
                <FormControl
                  data-test="password"
                  name="password_new"
                  type="password"
                  as={TextField}
                  label="New Password"
                />
              </InputGroup>
            </Col>
            <Col spaceBelow md={12}>
              <InputGroup prefixIcon="asterisk">
                <FormControl
                  data-test="passwordConfirm"
                  name="password_repeat"
                  type="password"
                  as={TextField}
                  label="Confirm New Password"
                />
              </InputGroup>
            </Col>
            <Col md={12}>
              <ProgressButton
                data-test="progressButton"
                type="submit"
                variant="danger"
                size="xLarge"
                fullWidth
                disabled={resetting}
                progress={resetting}
              >
                Change Password
              </ProgressButton>
            </Col>
          </Row>
        </Form>
      </Formik>
    </LandlubberContent>
  );
}
