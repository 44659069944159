import { MouseEvent, useRef, useState } from 'react';
import type { Row } from '@tanstack/react-table';
import {
  useReactTable,
  getPaginationRowModel,
  getCoreRowModel,
  flexRender,
  ColumnDef,
  SortingState,
  getSortedRowModel,
} from '@tanstack/react-table';
import clsx from 'clsx';
import styled from '@emotion/styled';
import TextCell from './cellRenderer/TextCell';
import { COLOR, GREYSCALE } from '../../styles/colors';
import { TYPOGRAPHY } from '../../styles/typography';
import { SPACING } from '../../styles/spacing';
import { BORDER_RADIUS, BORDER_WIDTH } from '../../styles/borders';
import withOpacity from '../../utils/withOpacity';
import Pagination from './controls/Pagination';
import { GRID_PAGE_SIZE_OPTIONS } from '../../constants';
import useIsMobile from '../../hooks/useIsMobile';
import BaseLoading from '../loading/BaseLoading';
import SimpleColumnHeader from './controls/SimpleColumnHeader';
import HorizontalGridScrollWrapper from './controls/HorizontalGridScrollWrapper';
import isHorizontallyScrolling from './utils/isHorizontallyScrolling';
import complexTextCellSort from './utils/complexTextCellSort';
import PageSizeSelect from './controls/PageSizeSelect';
import PageSizeInfo from './controls/PageSizeInfo';
import { MEDIA_QUERY } from '../../styles/breakpoints';

export const StyledSimpleDataGridContainer = styled.div<{
  showPagination: boolean;
  hasError: boolean;
}>`
  border-style: solid;
  border-color: ${({ hasError }) => (hasError ? COLOR.red : GREYSCALE.grey30)};
  border-width: ${({ showPagination }) =>
    showPagination
      ? `${BORDER_WIDTH.sm} ${BORDER_WIDTH.sm} ${BORDER_WIDTH.none}`
      : BORDER_WIDTH.sm};
  border-radius: ${({ showPagination }) =>
    showPagination
      ? `${BORDER_RADIUS.sm} ${BORDER_RADIUS.sm} ${BORDER_RADIUS.none} ${BORDER_RADIUS.none}`
      : BORDER_RADIUS.sm};
  border-spacing: 0;
  border-collapse: separate;
`;

export const StyledSimpleDataGridPlaceholder = styled.b`
  text-align: center;
`;

export const StyledSimpleDataGridTable = styled.table<{
  showPlaceholder?: boolean;
  isHorizontallyScrolling: boolean;
}>`
  width: 100%;
  border-collapse: collapse;
  font-size: ${TYPOGRAPHY.fontSize.sm};
  line-height: 20px;

  > thead > tr > th > .icons {
    font-size: ${TYPOGRAPHY.fontSize.md};
    float: right;

    > .icon {
      vertical-align: middle;
    }
  }

  > tbody > tr.clickable:hover {
    cursor: pointer;
    background-color: ${GREYSCALE.black};
    color: ${GREYSCALE.white};
  }

  tr th,
  tr td {
    padding: ${SPACING.md};
    border-color: ${GREYSCALE.grey30};
    border-style: solid;
    border-width: ${BORDER_WIDTH.none} ${BORDER_WIDTH.xs} ${BORDER_WIDTH.xs} ${BORDER_WIDTH.none};
    vertical-align: middle;

    :last-of-type {
      border-right-width: ${BORDER_WIDTH.none};
    }
  }

  tr th {
    font-weight: ${TYPOGRAPHY.fontWeight.medium};
    padding: ${SPACING.lg} ${SPACING.md};
  }

  tr {
    &:last-of-type {
      td {
        border-bottom-width: ${(props) =>
          props.isHorizontallyScrolling ? BORDER_WIDTH.xs : BORDER_WIDTH.none};
      }
    }
  }

  tr th {
    color: ${GREYSCALE.grey60};
    background-color: ${GREYSCALE.grey20};
    border-color: ${GREYSCALE.grey30};
    border-bottom-width: ${BORDER_WIDTH.sm};
    text-align: left;
    &:first-of-type {
      border-top-left-radius: ${BORDER_RADIUS.xxs};
    }

    &:last-of-type {
      border-top-right-radius: ${BORDER_RADIUS.xxs};
    }

    &.sortingActive {
      background-color: ${GREYSCALE.grey30};
    }
  }
`;

const Styled = {
  GridFooter: styled.footer`
    font-size: ${TYPOGRAPHY.fontSize.sm};
    line-height: 20px;
    display: grid;
    grid-template-columns: 1fr 200px auto auto;
    column-gap: 1.5em;
    grid-template-areas: 'pagination footerinfo pagesizeselect pagesizeinfo';
    padding: 0.5em 0.75em;
    align-items: center;
    padding: ${SPACING.sm} ${SPACING.xs};
    border-width: ${BORDER_WIDTH.sm};
    border-color: ${GREYSCALE.grey30};
    border-style: solid;
    border-radius: ${BORDER_RADIUS.none} ${BORDER_RADIUS.none} ${BORDER_RADIUS.sm}
      ${BORDER_RADIUS.sm};
    background-color: ${GREYSCALE.grey10};
    color: ${GREYSCALE.grey50};
    box-shadow: inset 0 1px 0 ${withOpacity(GREYSCALE.white, 0.2)},
      0 1px 2px ${withOpacity(GREYSCALE.black, 0.05)};
    @media (max-width: ${MEDIA_QUERY.mdMax}) {
      grid-template-areas: 'pagination pagesizeselect pagesizeinfo';
      // grid-template-areas: 'pagination pagesizeselect' 'footerinfo pagesizeinfo'; can be uncommented to use 2-row layout (see ImportGridPage)
      column-gap: 0.5em;
      grid-template-columns: 1fr 1fr;
      //grid-template-rows: repeat(2, 1fr); can be uncommented to use 2-row layout
    }
  `,
  PlaceholderWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${SPACING.lg};
    font-size: ${TYPOGRAPHY.fontSize.md};
    border-radius: ${BORDER_RADIUS.none} ${BORDER_RADIUS.none} ${BORDER_RADIUS.sm}${BORDER_RADIUS.sm};
  `,
  LoadingWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${SPACING.lg};
  `,
  ErrorMessage: styled.p`
    color: ${COLOR.red};
    text-align: right;
    margin: ${SPACING.sm} ${SPACING.none} ${SPACING.none};
    font-size: ${TYPOGRAPHY.fontSize.xs};
    font-weight: ${TYPOGRAPHY.fontWeight.medium};
  `,
};

type SimpleDataGridProps<TData extends unknown, TValue extends unknown> = {
  id?: string;
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  sortable?: boolean;
  loading?: boolean;
  showPaginationThreshold?: number;
  maxPageSize?: number;
  totalItemCount?: number;
  initialPageIndex?: number;
  initialPageSize?: number;
  initialSorting?: SortingState;
  onRowClick?: (row: Row<TData>, event: MouseEvent) => void;
  paginationPageButtonCount?: number;
  placeholder?: React.ReactNode; // an element that renders when there is no data in the response
  error?: string; // on the upload mapping page, it is possible to have errors in the columns (that are fields in a formik form)
};

function SimpleDataGrid<TData>({
  id,
  columns,
  data,
  sortable = false,
  loading = false,
  showPaginationThreshold = 100,
  maxPageSize = 1000,
  totalItemCount = data.length,
  initialPageIndex = 0,
  initialPageSize = GRID_PAGE_SIZE_OPTIONS[0],
  initialSorting = [],
  onRowClick,
  placeholder,
  paginationPageButtonCount,
  error,
}: SimpleDataGridProps<TData, any>) {
  const { isMobile } = useIsMobile();
  const scrollableRef = useRef<HTMLDivElement>(null);

  const showPagination =
    showPaginationThreshold &&
    totalItemCount > showPaginationThreshold &&
    totalItemCount > initialPageSize;

  const [sorting, setSorting] = useState<SortingState>(initialSorting);

  const table = useReactTable({
    columns,
    data,
    sortingFns: {
      complexTextCellSort,
    },
    state: {
      sorting,
    },
    initialState: {
      pagination: {
        pageIndex: initialPageIndex,
        pageSize: initialPageSize,
      },
    },
    meta: {
      loading,
      sortable,
    },
    onSortingChange: setSorting,
    defaultColumn: { cell: TextCell, header: 'SimpleColumnHeader' },
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const { pagination } = table.getState();
  const tableTotalSize = table.getTotalSize();
  const { rows } = showPagination ? table.getRowModel() : table.getPrePaginationRowModel();
  const showPlaceholder = !!placeholder && !loading && !rows.length;

  return (
    <>
      <StyledSimpleDataGridContainer showPagination={!!showPagination} hasError={Boolean(error)}>
        <HorizontalGridScrollWrapper ref={scrollableRef} tableTotalSize={tableTotalSize}>
          <StyledSimpleDataGridTable
            id={id}
            showPlaceholder={showPlaceholder}
            isHorizontallyScrolling={isHorizontallyScrolling(scrollableRef)}
          >
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <SimpleColumnHeader
                      key={header.id}
                      sortable={table.options.meta?.sortable}
                      column={header.column}
                      headerId={header.id}
                    />
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {loading && (
                <tr>
                  <td className="loadingRow" colSpan={table.getAllColumns().length}>
                    <Styled.LoadingWrapper>
                      <BaseLoading />
                    </Styled.LoadingWrapper>
                  </td>
                </tr>
              )}
              {showPlaceholder && (
                <tr>
                  <td colSpan={table.getAllColumns().length}>
                    <Styled.PlaceholderWrapper>{placeholder}</Styled.PlaceholderWrapper>
                  </td>
                </tr>
              )}
              {!loading &&
                rows.length > 0 &&
                rows.map((row) => (
                  <tr
                    key={row.id}
                    className={clsx({ clickable: onRowClick })}
                    onClick={(event) => onRowClick && onRowClick(row, event)}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </StyledSimpleDataGridTable>
        </HorizontalGridScrollWrapper>
      </StyledSimpleDataGridContainer>
      {showPagination && (
        <Styled.GridFooter data-testid="table-footer">
          <Pagination
            canNextPage={table.getCanNextPage()}
            canPreviousPage={table.getCanPreviousPage()}
            gotoPage={table.setPageIndex}
            previousPage={table.previousPage}
            nextPage={table.nextPage}
            pageCount={table.getPageCount()}
            pageIndex={pagination.pageIndex}
            buttonCount={paginationPageButtonCount}
          />
          <PageSizeSelect
            pageSize={pagination.pageSize}
            setPageSize={table.setPageSize}
            pageSizeOptions={GRID_PAGE_SIZE_OPTIONS}
            maxPageSize={maxPageSize}
          />
          <PageSizeInfo
            pageSize={pagination.pageSize}
            loading={loading}
            pageIndex={pagination.pageIndex}
            currentRowCount={rows.length}
            hidePagerInfo={isMobile}
            totalItemCount={totalItemCount}
            totalPageCount={table.getPageCount()}
          />
        </Styled.GridFooter>
      )}
      {error && <Styled.ErrorMessage>{error}</Styled.ErrorMessage>}
    </>
  );
}

export default SimpleDataGrid;
