import { QueryHookOptions, useLazyQuery, useQuery } from '@apollo/client';

import { gql } from '../../gql';
import {
  DownloadLabelByShipmentQuery,
  DownloadLabelByShipmentQueryVariables,
} from '../../gql/graphql';

export const downloadLabelByShipmentQuery = gql(/* GraphQL */ `
  query DownloadLabelByShipmentQuery($shipmentId: ID!, $pageLayout: PageLayout) {
    downloadLabelByShipment(shipmentId: $shipmentId, pageLayout: $pageLayout) {
      id
      status
      fileFormat
      labelSize
      pageLayout
      url
      shipments {
        id
        canInstantRefundShipment
        printed
        canPrint
        isShipmentRefundable
      }
    }
  }
`);

export const useDownloadLabelByShipmentQuery = (
  options: QueryHookOptions<DownloadLabelByShipmentQuery, DownloadLabelByShipmentQueryVariables>,
) => useQuery(downloadLabelByShipmentQuery, options);

export const useDownloadLabelByShipmentLazyQuery = (
  options: QueryHookOptions<DownloadLabelByShipmentQuery, DownloadLabelByShipmentQueryVariables>,
) => useLazyQuery(downloadLabelByShipmentQuery, options);
