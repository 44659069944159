import { useMutation } from '@apollo/client';
import { gql } from '../../gql';

export const resetPasswordMutation = gql(/* GraphQL */ `
  mutation ResetPasswordMutation($resetToken: String!, $newPassword: String!) {
    resetPassword(resetToken: $resetToken, newPassword: $newPassword) {
      ...BasicUserFragment
    }
  }
`);

export const useResetPasswordMutation = () => useMutation(resetPasswordMutation);
