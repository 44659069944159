import { useMutation } from '@apollo/client';
import { gql } from '../../gql';

export const updateNotificationsSettingsMutation = gql(/* GraphQL */ `
  mutation UpdateNotificationsSettingsMutation(
    $carrierAdjustmentsNotificationEnabled: Boolean!
    $paymentReceiptsNotificationEnabled: Boolean!
    $paymentFailuresNotificationEnabled: Boolean!
    $newLoginNotificationEnabled: Boolean!
    $carrierAdjustmentDisputesEnabled: Boolean!
    $newFeaturesAnnouncementsNotificationEnabled: Boolean!
  ) {
    updateNotificationsSettings(
      carrierAdjustmentsNotificationEnabled: $carrierAdjustmentsNotificationEnabled
      paymentReceiptsNotificationEnabled: $paymentReceiptsNotificationEnabled
      paymentFailuresNotificationEnabled: $paymentFailuresNotificationEnabled
      newLoginNotificationEnabled: $newLoginNotificationEnabled
      carrierAdjustmentDisputesEnabled: $carrierAdjustmentDisputesEnabled
      newFeaturesAnnouncementsNotificationEnabled: $newFeaturesAnnouncementsNotificationEnabled
    ) {
      carrierAdjustmentsNotificationEnabled
      paymentReceiptsNotificationEnabled
      paymentFailuresNotificationEnabled
      newLoginNotificationEnabled
      carrierAdjustmentDisputesEnabled
      newFeaturesAnnouncementsNotificationEnabled
    }
  }
`);

export const useUpdateNotificationsSettingsMutation = () =>
  useMutation(updateNotificationsSettingsMutation);
