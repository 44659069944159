import { ReactNode } from 'react';
import styled from '@emotion/styled';
import { ShipmentStatus } from '../../gql/graphql';
import IntercomArticleLink from '../IntercomArticleLink';
import Link from '../Link';
import formatCurrency from '../../utils/formatCurrency';
import { TYPOGRAPHY } from '../../styles/typography';
import { COLOR, GREYSCALE } from '../../styles/colors';
import { SPACING } from '../../styles/spacing';
import convertBbcode from '../../utils/convertBbcode';
import capitalize from '../../utils/capitalize';

type StatusTextProps = {
  status?: ShipmentStatus;
};

const Styled = {
  Wrapper: styled.div`
    padding-top: ${SPACING.sm};
  `,
  StatusInfo: styled.span<StatusTextProps>`
    color: ${({ status }) => {
      if (
        status === 'REFUND_DECLINED' ||
        status === 'REFUND_REVERSED' ||
        status === 'REFUND_FAILED' ||
        status === 'INVALID' ||
        status === 'RATE_FAILED' ||
        status === 'RETURN_EXCEEDED' ||
        status === 'VALIDATION_FAILED' ||
        status === 'PURCHASE_FAILED'
      ) {
        return COLOR.red;
      }
      if (status === 'REFUNDED') {
        return COLOR.green;
      }
      return GREYSCALE.grey50;
    }};
    font-size: ${TYPOGRAPHY.fontSize.sm};
  `,
  ErrorMessage: styled.span`
    color: ${COLOR.red};
    font-size: ${TYPOGRAPHY.fontSize.sm};
  `,
};

type ShipmentStatusTextProps = {
  shipment: {
    status: ShipmentStatus;
    totalClientPrice: number | null;
    carrierTitle: string;
    errorMessage: string;
    additionalRefundNotice: string;
  };
  accountBalance: number;
};

export default function ShipmentStatusText({ shipment, accountBalance }: ShipmentStatusTextProps) {
  const { status, totalClientPrice, carrierTitle, errorMessage, additionalRefundNotice } = shipment;

  if (status === 'PURCHASED') {
    return null;
  }

  const formattedPrice = formatCurrency(totalClientPrice);
  const intercomHref =
    'https://support.pirateship.com/en/articles/1068139-can-i-get-a-refund-if-i-make-a-mistake-or-don-t-need-it-anymore';
  const displayStatus = (() => {
    if (status === 'RETURN_PURCHASED') {
      return 'Return used';
    }
    return capitalize(status).replace('_', ' ');
  })();
  const previousStatus =
    status === 'REFUND_DECLINED' ? (
      <>
        <del>{totalClientPrice === 0 ? 'Refunded' : 'Refund requested'}</del>{' '}
      </>
    ) : (
      ''
    );

  const statusInfoMap: Map<ShipmentStatus, ReactNode> = new Map([
    [
      'REFUND_REQUESTED',
      <>
        {`Your request has been submitted to ${carrierTitle}. `}
        {carrierTitle === 'USPS' &&
          "USPS waits 2-4 weeks to make sure the label isn't used before refunding to your Pirate Ship account balance. "}
        <IntercomArticleLink href={intercomHref}>Learn more here</IntercomArticleLink>
      </>,
    ],
    [
      'REFUNDED',
      <>
        {`Refund approved. ${formattedPrice} was refunded to your Pirate Ship account and will be used for future postage purchases. View your account balance and all transactions on the `}
        <Link bridgeHref="/reports" to="/reports">
          Reports Page
        </Link>
        .
      </>,
    ],
    [
      'REFUND_REVERSED',
      <span>
        {`This label was used after it had been refunded, so the carrier has charged your account ${formattedPrice}.`}
      </span>,
    ],
    [
      'PURCHASE_FAILED',
      accountBalance > 0 ? (
        <>
          {'The credit from your payment has been saved in your '}
          <Link bridgeHref="/reports" to="/reports">
            Accounts Balance
          </Link>
          , ready for your next purchase.
        </>
      ) : null,
    ],
  ]);
  const detailedInfo = statusInfoMap.get(status) ?? null;

  return (
    <Styled.Wrapper>
      {status !== 'REFUNDED' && (
        <Styled.StatusInfo data-testid="statusInfo">
          Status: {previousStatus}
          {displayStatus}.{' '}
        </Styled.StatusInfo>
      )}
      {errorMessage && (
        <Styled.ErrorMessage data-testid="statusErrorMessage">
          {convertBbcode(errorMessage)}
        </Styled.ErrorMessage>
      )}
      {detailedInfo && (
        <Styled.StatusInfo data-testid="detailedInfo" status={status}>
          {' '}
          {detailedInfo}
        </Styled.StatusInfo>
      )}
      {additionalRefundNotice && (
        <Styled.StatusInfo data-testid="additionalRefundNotice">
          <br />
          {additionalRefundNotice}
        </Styled.StatusInfo>
      )}
    </Styled.Wrapper>
  );
}
