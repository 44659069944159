import ServiceSummaryTable from './StyledServiceSummaryTable';
import { CarrierIcon } from '../Icon';
import { ShipmentStatus } from '../../gql/graphql';
import { ExternalLink } from '../Link';

export type NullableTitle = {
  title: string;
} | null;

type ShipmentServiceSummaryServiceTitleProps = {
  shipment: {
    carrierKey: string;
    mailClassKey: string | null;
    mailClass: NullableTitle;
    mailClassOriginal: NullableTitle;
    zoneOriginal: string | null;
    zone: string | null;
    status: ShipmentStatus;
  };
  shipmentContainsInsuranceSurcharge: boolean;
  isUPSCInsuranceActive: boolean; // feature flag check, can be removed as prop when 100% rolled out.
};

export default function ShipmentServiceSummaryServiceTitle({
  shipment,
  shipmentContainsInsuranceSurcharge,
  isUPSCInsuranceActive,
}: ShipmentServiceSummaryServiceTitleProps) {
  return (
    <ServiceSummaryTable.ServiceTitleWrapper>
      <CarrierIcon
        carrierKey={shipment.carrierKey.toLocaleUpperCase()}
        mailClassKey={shipment.mailClassKey}
      />
      {shipment.mailClassOriginal?.title !== shipment.mailClass?.title && (
        <ServiceSummaryTable.Strikethrough>
          {shipment.mailClassOriginal?.title}
        </ServiceSummaryTable.Strikethrough>
      )}
      {shipment.mailClass?.title}{' '}
      {shipment.carrierKey === 'usps' && (
        <>
          {` • `}
          {shipment.zoneOriginal !== shipment.zone && (
            <ServiceSummaryTable.Strikethrough>
              {`Zone ${shipment.zoneOriginal}`}
            </ServiceSummaryTable.Strikethrough>
          )}
          {`Zone ${shipment.zone}`}
        </>
      )}
      {shipment.status.includes('RETURN') ? ` • Return Label` : ''}
      {/* Show the link to file a carrier liability claim on UPS */}
      {/* only do this if no additional insurance has been purchased, the shipment was bought with UPS and the feature flag is on */}
      {!shipmentContainsInsuranceSurcharge &&
        isUPSCInsuranceActive &&
        shipment.carrierKey === 'ups' && (
          <ExternalLink href="https://www.placeholderlinktoUPSC.com">
            File an Insurance Claim
          </ExternalLink>
        )}
    </ServiceSummaryTable.ServiceTitleWrapper>
  );
}
