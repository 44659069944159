import { useEffect } from 'react';
import styled from '@emotion/styled';
import { useStateDistributionChartLazyQuery } from '../../../../operations/queries/stateDistributionChart';
import { DatetimeRangeInput } from '../../../../gql/graphql';
import { REPORT_TILE_SIZE } from '../../../../constants';
import ReportsTile from './ReportsTile';
import ReportsTop5 from './ReportsTop5';
import { Col } from '../../../layout/Grid';
import UsMapChart from '../../../charts/UsMapChart';

export type ReportsStateDistributionProps = {
  dateFormatter: () => DatetimeRangeInput;
};

const Styled = {
  Top5Tile: styled(ReportsTile)`
    @media print {
      height: calc(2.5 * ${REPORT_TILE_SIZE.Print}) !important;
    }
  `,
};

export default function ReportsStateDistribution({ dateFormatter }: ReportsStateDistributionProps) {
  const [
    loadStateDistributionChart,
    { loading: stateDistributionChartLoading, data: stateDistributionChartData },
  ] = useStateDistributionChartLazyQuery({
    variables: {
      datetimeRange: dateFormatter(),
    },
  });

  useEffect(() => {
    loadStateDistributionChart({
      variables: {
        datetimeRange: dateFormatter(),
      },
    });
  }, [dateFormatter, loadStateDistributionChart]);

  return (
    <>
      <Col md={4}>
        <Styled.Top5Tile
          labelId="reports-state-distribution-headline"
          title="Top States"
          fullHeight
          loading={stateDistributionChartLoading}
        >
          {!stateDistributionChartLoading && (
            <ReportsTop5
              distributions={
                stateDistributionChartData?.stateDistributionChart.stateDistributions ?? []
              }
            />
          )}
        </Styled.Top5Tile>
      </Col>
      <Col md={8}>
        <UsMapChart
          data={stateDistributionChartData?.stateDistributionChart.stateDistributions ?? []}
          customOptions={{
            chart: {
              className: 'screen-chart',
            },
          }}
        />
        <UsMapChart
          data={stateDistributionChartData?.stateDistributionChart.stateDistributions ?? []}
          customOptions={{
            chart: {
              className: 'print-chart',
              width: 600,
              height: 350,
            },
            mapNavigation: {
              enabled: false,
            },
            plotOptions: {
              map: {
                dataLabels: {
                  x: -5,
                  y: -5,
                },
              },
            },
          }}
        />
      </Col>
    </>
  );
}
