import { ApolloError, useLazyQuery } from '@apollo/client';
import styled from '@emotion/styled';
import isEqualDate from 'date-fns/isEqual';
import { Form, Formik, FormikProps } from 'formik';
import isEmpty from 'lodash.isempty';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { dispatch } from 'use-bus';
import * as yup from 'yup';
import { setFlashMessage } from '../../../apollo/cache/flashMessages';
import {
  BATCH_PROCESS_POLL_INTERVAL,
  DATE_FORMAT,
  PaymentMethodModalVia,
  PaymentMethodPromotion,
  STORAGE_KEYS,
  SubmitStep,
  UiEvents,
} from '../../../constants';
import { BatchQuery, RateGroupKey, RateGroupTrait } from '../../../gql/graphql';
import useDateInUserTimezone from '../../../hooks/useDateInUserTimezone';
import useLogger from '../../../hooks/useLogger';
import useMappedCountries from '../../../hooks/useMappedCountries';
import useNavigateOrHref from '../../../hooks/useNavigateOrHref';
import usePayPal from '../../../hooks/usePayPal';
import usePlaid, { PlaidHookProps } from '../../../hooks/usePlaid';
import { useBuyBatchMutation } from '../../../operations/mutations/buyBatch';
import { useCreateUspsMerchantAccountMutation } from '../../../operations/mutations/createUspsMerchant';
import { useDeleteBatchMutation } from '../../../operations/mutations/deleteBatch';
import { useRerateBatchMutation } from '../../../operations/mutations/rerateBatch';
import { useSetBatchToNewMutation } from '../../../operations/mutations/setBatchToNew';
import { useUpdateBatchTitleMutation } from '../../../operations/mutations/updateBatchTitle';
import { useBatchQuery } from '../../../operations/queries/batch';
import { batchProcessStatusQuery } from '../../../operations/queries/batchProcessStatus';
import { getItem, removeItem, setItem } from '../../../services/storage';
import { SPACING } from '../../../styles/spacing';
import blockForwardingProps from '../../../utils/blockForwardingProps';
import logBuyEventToDatadog from '../../../utils/buyPageTracking';
import convertCurrencyToNumber from '../../../utils/convertCurrencyToNumber';
import formatCurrency from '../../../utils/formatCurrency';
import getFormattedRateSelectionInput from '../../../utils/formatRateSelectionInput';
import getSelectedRateSummaries from '../../../utils/getSelectedRateSummaries';
import roundFloat from '../../../utils/roundFloat';
import sleep from '../../../utils/sleep';
import RecipientAddress from '../../RecipientAddress';
import BatchTitle from '../../form/BatchTitle';
import Label from '../../form/Label';
import { Col, PageContainer, Row } from '../../layout/Grid';
import ShipmentFlowPageFooter from '../../layout/ShipmentFlowPageFooter';
import PageLoading from '../../loading/PageLoading';
import AddPaymentMethodModal from '../../modals/AddPaymentMethodModal';
import UpsAccountModal from '../../modals/UpsAccountModal';
import UspsAddressCorrectionModal from '../../modals/UspsAddressCorrectionModal';
import ShipmentDetailsBox from '../../shipmentDetailsBox/BuyPageShipmentDetailsBox';
import LoadingAnimation from './LoadingAnimation';
import FinishPurchaseSubform, {
  FinishPurchaseSubformValues,
} from '../../subforms/FinishPurchaseSubform';
import RateGroupsSubform, { RateGroupsSubformValues } from '../../subforms/RateGroupsSubform';
import NotifyRecipientsSettingRow, {
  NotifyRecipientsSettingRowValues,
} from '../../subforms/finishPurchase/NotifyRecipientsSettingRow';
import ShipDateSettingRow, {
  ShipDateSettingRowValues,
} from '../../subforms/finishPurchase/ShipDateSettingRow';
import {
  notifyRecipientsSettingRowInitialValues,
  notifyRecipientsSettingRowValidationSchema,
} from '../../subforms/finishPurchase/notifyRecipientsSettingRowUtils';
import {
  shipDateSettingRowInitialValues,
  shipDateSettingRowValidationSchema,
} from '../../subforms/finishPurchase/shipDateSettingRowUtils';
import {
  calculateMinimumCharge,
  finishPurchaseSubformInitialValues,
  finishPurchaseValidationSchema,
  getAvailableShipDates,
} from '../../subforms/finishPurchaseSubformUtils';
import {
  getRateGroupsInitialState,
  rateGroupsSubformValidationSchema,
} from '../../subforms/rateGroupsSubformUtils';
import { PageContainerProps } from '../settings/BillingPage';
import environment from '../../../utils/environment';
import {
  PlaidAccessTokenProps,
  PlaidSuccessCallbackProps,
} from '../../../hooks/usePlaidAccessToken';
import { PlaidLinkTokenProps } from '../../../hooks/usePlaidLinkToken';

const Styled = {
  // to overcome legacy print styles in bridge
  PageContainer: styled(PageContainer, blockForwardingProps('isModalOpen'))<PageContainerProps>`
    @media print {
      @page {
        size: letter portrait;
        margin: 0.5in;
      }
      ${(props) => (props.isModalOpen ? 'display: none' : '')};
    }
  `,
  TitleWrapper: styled.div`
    margin-bottom: ${SPACING.xxl};
  `,
};

export type BuyFormValues = {
  summaryIds: RateGroupsSubformValues;
  shipDate: ShipDateSettingRowValues;
  notifyRecipients: NotifyRecipientsSettingRowValues;
  finishPurchase: FinishPurchaseSubformValues;
};

type RateGroup = BatchQuery['batch']['rateGroups'][0];

// determine what carriers are in the selected rate groups in the form
function determineSelectedCarriers(
  selectedSummaryIds: Record<string, string>,
  rateGroups: ReadonlyArray<RateGroup>,
): { ups: boolean; usps: boolean } {
  if (selectedSummaryIds && !isEmpty(selectedSummaryIds)) {
    const selectedSummaries = getSelectedRateSummaries(selectedSummaryIds, rateGroups);
    return {
      ups: selectedSummaries.some((summary) => summary.carrier.carrierKey === 'ups'),
      usps: selectedSummaries.some((summary) => summary.carrier.carrierKey === 'usps'),
    };
  }
  return { ups: false, usps: false };
}

function isReturnLabelsOnly(groupKey: RateGroupKey): boolean {
  const directionLayer = groupKey.traits.find(
    (trait: RateGroupTrait) => trait.layer === 'Direction',
  );

  return directionLayer?.value === 'RETURN';
}

// find the total cost of all selected labels
function getTotalCost(
  selectedSummaryIds: Record<string, string>,
  rateGroups: ReadonlyArray<RateGroup>,
): number {
  if (selectedSummaryIds && !isEmpty(selectedSummaryIds)) {
    const selectedSummaries = getSelectedRateSummaries(selectedSummaryIds, rateGroups);
    // Only add the label cost to the total price if it is NOT a return label, we don't charge for these at this point
    const costRelatedSummaries = selectedSummaries.filter(
      (summary) => !isReturnLabelsOnly(summary.groupKey),
    );
    return roundFloat(
      costRelatedSummaries.reduce((sum, summary) => sum + summary.totalPrice, 0),
      2,
    );
  }
  return 0;
}

function getInitialSelectedSummaryIds(
  rateGroups: BatchQuery['batch']['rateGroups'],
  rateGroupSortOrder: BatchQuery['user']['rateGroupSortOrder'],
  forwardedMailClassKey: string | undefined,
) {
  const preselects = getItem<Record<string, string>>(STORAGE_KEYS.preselectedSummaryIdsStorageKey);

  // after getting the initial values, set the Summary IDs to these values
  // then unset the local storage variable because we don't need it anymore!
  if (preselects) {
    removeItem(STORAGE_KEYS.preselectedSummaryIdsStorageKey);
    return preselects;
  }
  // if coming from inApp calc -> single shipment -> here, preselect the passed
  // forwarded mail class key
  if (forwardedMailClassKey && rateGroups.length === 1) {
    const summary = rateGroups[0].rateSummaries.find(
      (rs) => rs.firstMailClass.mailClassKey === forwardedMailClassKey,
    );
    if (summary) {
      return { [rateGroups[0].groupKey.string]: summary.uniqueId };
    }
  }
  // otherwise, get the initial state the standard way
  return getRateGroupsInitialState(rateGroups, rateGroupSortOrder);
}

export type BuyPageProps = {
  entityId?: string; // passed if we are in the bridge
  forwardedMailClassKey?: string; // passed if we are in the bridge
  triggerGetStep?: () => void; // function to call after a status transition is made
};

export default function BuyPage({ entityId, forwardedMailClassKey, triggerGetStep }: BuyPageProps) {
  const navigateOrHref = useNavigateOrHref();
  const navigate = useNavigate();
  const logger = useLogger();
  const [searchParams] = useSearchParams();
  const { batchId } = useParams<'batchId'>();
  const id = entityId || batchId || '';
  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false);
  const [showUpsAccountModal, setShowUpsAccountModal] = useState(false);
  const [showUspsAddressCorrectionModal, setShowUspsAddressCorrectionModal] = useState(false);
  const [upsAccountModalHeadline, setUpsAccountModalHeadline] = useState('');
  const formikRef = useRef<FormikProps<BuyFormValues> | null>(null);
  const [buyInProgress, setBuyInProgress] = useState(false);
  const {
    userTimezoneMinutesOffset,
    formatDate,
    createDate,
    loading: offsetLoading,
  } = useDateInUserTimezone();

  const [paymentMethodModalVia, setPaymentMethodModalVia] =
    useState<PaymentMethodModalVia>('submit');
  const [paymentMethodPromotion, setPaymentMethodPromotion] =
    useState<PaymentMethodPromotion>('none');
  const [backLoading, setBackLoading] = useState(false); // used to block UI when leaving the bridged buy page via a back/cancel link

  const [rerateCheckDone, setRerateCheckDone] = useState(false);
  const [rerateInProgress, setRerateInProgress] = useState(false);
  const [plaidInProgress, setPlaidInProgress] = useState(false);
  const [runningProcess, setRunningProcess] = useState<BatchQuery['batch']['runningProcess']>(null);
  const [rerateBatch] = useRerateBatchMutation();
  const [fetchBatchProcessStatus] = useLazyQuery(batchProcessStatusQuery);
  const [updateBatchTitle] = useUpdateBatchTitleMutation();
  const [createUspsMerchantAccount] = useCreateUspsMerchantAccountMutation();
  const [buyBatch] = useBuyBatchMutation();
  const [deleteBatch] = useDeleteBatchMutation();
  const [setBatchToNew] = useSetBatchToNewMutation();
  const [totalCost, setTotalCost] = useState(0.0);

  const pollBatchProcessStatus = useCallback(async () => {
    let batchProcessRunning = true;
    let batchProcessStatus;

    do {
      // We explicitly don't want to parallelize these requests as we poll for an updated status
      // eslint-disable-next-line no-await-in-loop
      await sleep(BATCH_PROCESS_POLL_INTERVAL);
      // eslint-disable-next-line no-await-in-loop
      batchProcessStatus = await fetchBatchProcessStatus({
        variables: { id },
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        onError: (error) => {
          logger.error('Error occurred while rerating the batch', undefined, error);
        },
      });
      // data is never undefined when errorPolicy is 'all'
      batchProcessRunning = batchProcessStatus.data!.batch.status === 'RUNNING';
      setRunningProcess(batchProcessStatus.data!.batch.runningProcess);
    } while (batchProcessRunning);

    return batchProcessStatus;
  }, [fetchBatchProcessStatus, id, logger]);

  // Main data fetching
  const { data, refetch: refetchBatchQuery } = useBatchQuery({
    variables: {
      id,
    },
    // Required to have onCompleted execute on refetches
    notifyOnNetworkStatusChange: true,
    onCompleted: ({
      batch: {
        shipmentStatusSummary: { errorCount },
        status,
        shipDatePossibleNow,
        rateGroups,
        runningProcess: runningProcessResult,
      },
      user: { rateGroupSortOrder },
    }) => {
      // Show flash errors if our batch returns errors
      if (errorCount > 0) {
        const amount = errorCount === 1 ? 'One' : errorCount;
        const address = errorCount === 1 ? 'address' : 'addresses';
        setFlashMessage(
          `Note: ${amount} ${address} will not be included in the purchase due to errors. You can correct and re-import the ${address} after buying.`,
          'info',
        );
      }

      // Set total cost local state
      setTotalCost(
        getTotalCost(
          getInitialSelectedSummaryIds(rateGroups, rateGroupSortOrder, forwardedMailClassKey),
          rateGroups,
        ),
      );

      // Navigate to the batch page if the batch is ready to print
      if (status === 'READY_TO_PRINT') {
        navigate(`/batch/${id}`);
        return;
      }

      // Rerate automatically if the ship date is not possible now OR we are missing rate data.
      // Don't re-rate if a process is still running.
      if (status === 'IDLE' && (!shipDatePossibleNow || rateGroups.length === 0)) {
        performRerate(id, null);
      } else {
        setRerateCheckDone(true);
      }

      // Poll for the batch process status if it is running
      if (runningProcessResult) {
        setRunningProcess(runningProcessResult);
        pollBatchProcessStatus().then(async (batchProcessStatus) => {
          switch (batchProcessStatus.data?.batch.status) {
            case 'READY_TO_PRINT':
              await handleBuyComplete();
              break;
            case 'IDLE':
              setRerateInProgress(true);
              await handleRerateComplete();
              setRerateInProgress(false);
              break;
            default:
              break;
          }
        });
      }
    },
  });

  async function handleBuyComplete() {
    // Navigate to the batch details page (by telling the switcher component)
    triggerGetStep?.();

    // Trigger logo animation
    dispatch(UiEvents.BatchPurchased);
  }

  async function handleRerateComplete() {
    // Refetch the batch data after rerating
    const updatedBatch = await refetchBatchQuery();
    triggerGetStep?.();

    // In case the backend returns with another ship date (auto-advance), update the form
    formikRef.current?.setFieldValue('shipDate.date', createDate(updatedBatch.data.batch.shipDate));
  }

  const onPlaidSuccess = (payload: PlaidSuccessCallbackProps) => {
    if (payload?.successMessage) {
      setFlashMessage(payload?.successMessage, 'success');
    }

    setPlaidInProgress(false);
    formikRef.current?.submitForm();
  };

  const onPlaidError = (msg: string) => {
    setFlashMessage(msg, 'danger');
    setPlaidInProgress(false);
    setBuyInProgress(false);
  };

  const onPlaidEvent = (event: string) => {
    if (event === 'EXIT') {
      setPlaidInProgress(false);
      setBuyInProgress(false);
    }
  };

  const { getPlaidLinkToken } = usePlaid({
    onSuccessCallback: onPlaidSuccess,
    onErrorCallback: onPlaidError,
    onEventCallback: onPlaidEvent,
    forceOpen: true,
  } as PlaidAccessTokenProps & PlaidLinkTokenProps & PlaidHookProps);

  async function performRerate(
    requestedBatchId: string,
    requestedShipDate: Date | null,
    selectedSummaryIds?: Record<string, string>,
  ) {
    // Mark rerate started
    setRerateInProgress(true);

    await rerateBatch({
      variables: {
        id: requestedBatchId,
        shipDate:
          requestedShipDate !== null
            ? formatDate('local', requestedShipDate, DATE_FORMAT.date)
            : null,
      },
    });

    // Save selected summary ids for reloading the page after rerating
    if (environment.isBridge() && selectedSummaryIds) {
      setItem(STORAGE_KEYS.preselectedSummaryIdsStorageKey, selectedSummaryIds);
    }

    // Poll for the batch status until it is not RUNNING anymore
    await pollBatchProcessStatus();

    // Reload rate groups after re-rating
    await handleRerateComplete();

    // Mark rerate complete
    setRerateInProgress(false);
  }

  async function performBuy(values: BuyFormValues) {
    const {
      shipDate: { date: selectedShipDate },
      notifyRecipients,
      finishPurchase,
      summaryIds,
    } = values;

    if (
      !data ||
      // Payment source is required
      finishPurchase.paymentSourceId === null ||
      // RateGroups not initialized yet
      !summaryIds ||
      isEmpty(summaryIds)
    ) {
      setBuyInProgress(false);
      setFlashMessage('Something has gone wrong', 'danger');
      return;
    }

    try {
      await buyBatch({
        variables: {
          id: data.batch.id,
          rateSelection: getFormattedRateSelectionInput(summaryIds, data.batch),
          paymentSourceId: finishPurchase.paymentSourceId,
          shipDate: formatDate('local', selectedShipDate, DATE_FORMAT.date),
          totalCharge: convertCurrencyToNumber(finishPurchase.totalCharge),
          mailTemplateId: notifyRecipients.enabled ? notifyRecipients.emailTemplate : null,
          notifyRecipientsDate:
            notifyRecipients.enabled && notifyRecipients.date
              ? formatDate('local', notifyRecipients.date, DATE_FORMAT.dateTime24) // we explicity send the date in user time, the backend controller converts and saves it as UTC
              : null,
        },
        context: { errorHandled: true },
      });
    } catch (error) {
      if (error instanceof ApolloError) {
        error.graphQLErrors.forEach(({ extensions, message }) => {
          if (environment.isBridge() && extensions?.redirect) {
            window.location.reload();
            return;
          }

          if (extensions?.failureReason === 'PLAID_UNLINKED_ACCOUNT') {
            setPlaidInProgress(true);
            getPlaidLinkToken('buy_page', finishPurchase.paymentSourceId);
            return;
          }

          // a reload of the page will trigger the TermsModal in SideBar.tsx to show
          if (extensions?.failureReason === 'USER_DID_NOT_ACCEPT_TERMS') {
            window.history.scrollRestoration = 'manual';
            window.location.reload();
            return;
          }

          setFlashMessage(message, 'danger');
        });
      }

      setBuyInProgress(false);
      return;
    }

    // Track buy event
    logBuyEventToDatadog(values.summaryIds, data.batch);

    // Flag users with a batch in intercom
    if (data.batch.numShipments > 1 && typeof window.Intercom !== 'undefined') {
      window.Intercom('trackEvent', 'batch-purchased');
    }

    // If in the bridge in the legacy shipment flow, reload the page and rely on the backend to show the loading screen
    if (environment.isBridge() && searchParams.get('id') !== null) {
      window.history.scrollRestoration = 'manual';
      window.location.reload();

      return;
    }

    // Poll for the batch status until it is not RUNNING anymore
    await pollBatchProcessStatus();

    // Redirect to the batch page if the batch is ready to print
    await handleBuyComplete();
  }

  // pre-loading PayPal so the button appears much faster on AddPaymentMethodModal
  const getPaypalApiKey = () => {
    if (data?.company.paymentSources && data?.company.paymentSources.length > 0) {
      return undefined;
    }

    return data?.paymentApiMethods.find((e) => e.code === 'paypal')?.apiKey;
  };
  usePayPal(getPaypalApiKey());

  const hasMultipleShipments = data && data.batch.numShipments > 1;

  const countryMap = useMappedCountries();

  useEffect(() => {
    if (
      data?.company?.features.find((f: { key: string }) => f?.key === 'Plaid.promotion.experiment')
        ?.value &&
      !data?.company?.hasAnyPlaidPaymentSource &&
      data?.company?.paymentSources.length > 0
    ) {
      setPaymentMethodPromotion('plaidThreePercent');
    }
  }, [
    data?.company?.features,
    data?.company?.hasAnyPlaidPaymentSource,
    data?.company?.paymentSources,
  ]);

  // check everything before performing buy
  const onSubmit = async (values: BuyFormValues, step: SubmitStep) => {
    if (!data) return;
    setBuyInProgress(true);

    const userWantsGroundSaver = getSelectedRateSummaries(
      values.summaryIds,
      data.batch.rateGroups,
    ).some((r) => r.firstMailClass.mailClassKey === '93'); // ("93" is the UPS Ground Saver mailClassKey)
    const userHasSelected = determineSelectedCarriers(values.summaryIds, data.batch.rateGroups);
    const hasUpsMerchantAccount = data.company.createdMerchantAccounts.includes('ups');
    const { uspsMerchantAccountStatus, upsMerchantAccountStatus } = data.company;

    // 1. Show payment source modal if the company does not have a payment source yet
    if (data.company.paymentSources.length === 0 && step < SubmitStep.PaymentGood) {
      setPaymentMethodModalVia('submit'); // inform modal what opened it
      setShowPaymentMethodModal(true);
      return;
    }

    // 2. Handle USPS account creation and possible address correction
    if (userHasSelected.usps && step < SubmitStep.UspsGood) {
      // 2a. User has no USPS account, make account for them now and continue
      if (uspsMerchantAccountStatus === 'not_transmitted' || uspsMerchantAccountStatus === '') {
        const { data: createUspsMerchantAccountData } = await createUspsMerchantAccount();
        // 2b. Something is wrong with the billing address, show the USPS address correction modal
        if (createUspsMerchantAccountData?.createUspsMerchantAccount.error) {
          setShowUspsAddressCorrectionModal(true);
          return;
        }
      }
      // 2c: User has previously tried but recieved a transmission error, show the USPS address correction modal
      if (uspsMerchantAccountStatus === 'transmission_error') {
        setShowUspsAddressCorrectionModal(true);
        return;
      }
    }

    // 3. Show UPS first purchase modal if needed
    if (userHasSelected.ups && step < SubmitStep.UpsGood) {
      // 3a. User has no UPS account, show the modal with "First Label" headline
      if (!hasUpsMerchantAccount) {
        setUpsAccountModalHeadline('You’re buying your first UPS label! 🥳');
        setShowUpsAccountModal(true);
        return;
      }
      // 3b. User has outdated terms and wants ground saver (so they need to update their terms for this)
      if (upsMerchantAccountStatus === 'OUTDATED' && userWantsGroundSaver) {
        setUpsAccountModalHeadline('You’re buying your first UPS® Ground Saver label! 🥳');
        setShowUpsAccountModal(true);
        return;
      }
    }

    // 4. Buy label(s)!
    performBuy(values);
  };

  const onRateGroupsChange = useCallback(
    (newSelectedSummaryIds: RateGroupsSubformValues) => {
      if (!data) {
        return;
      }

      const newTotalCost = getTotalCost(newSelectedSummaryIds, data.batch.rateGroups);
      const minimumTotalCharge = calculateMinimumCharge(
        data.company.accountBalance,
        newTotalCost,
        data.company.settings.defaultChargeAmount,
      );

      setTotalCost(newTotalCost);

      formikRef.current?.setFieldValue(
        'finishPurchase.totalCharge',
        formatCurrency(minimumTotalCharge, { showDollarSign: false }),
      );
    },
    [data],
  );

  // Check if everything is set up so that we can iniitate the form
  if (data === undefined || backLoading || offsetLoading || !rerateCheckDone) {
    return <PageLoading />;
  }

  const availableShipDates = getAvailableShipDates(data.batch.rateGroups).map((sdv) =>
    createDate(sdv),
  );

  const validationSchema = yup.object<BuyFormValues>({
    summaryIds: rateGroupsSubformValidationSchema().required(),
    shipDate: shipDateSettingRowValidationSchema().required(),
    notifyRecipients: notifyRecipientsSettingRowValidationSchema().required(),
    finishPurchase: finishPurchaseValidationSchema({
      accountBalance: data.company.accountBalance,
      totalCost,
    }).required(),
  });

  return (
    <Styled.PageContainer isModalOpen={showPaymentMethodModal}>
      <Formik<BuyFormValues>
        innerRef={formikRef}
        validationSchema={validationSchema}
        initialValues={{
          summaryIds: getInitialSelectedSummaryIds(
            data.batch.rateGroups,
            data.user.rateGroupSortOrder,
            forwardedMailClassKey,
          ),
          shipDate: shipDateSettingRowInitialValues(createDate(data.batch.shipDate)),
          notifyRecipients: notifyRecipientsSettingRowInitialValues({
            defaultTrackingEmailsEnabled: data.company.settings.defaultTrackingEmailsEnabled,
            defaultTrackingEmailsDelay: data.company.settings.defaultTrackingEmailsDelay,
            defaultEmailTemplateId: data.company.settings.defaultEmailTemplateId,
            userTimezoneMinutesOffset,
            mailTemplates: data.company.mailTemplates,
          }),
          finishPurchase: finishPurchaseSubformInitialValues({
            accountBalance: data.company.accountBalance,
            totalCost,
            defaultPaymentSourceId: data.company.settings.defaultPaymentSourceId,
            defaultChargeAmount: data.company.settings.defaultChargeAmount,
          }),
        }}
        onSubmit={(values) => onSubmit(values, SubmitStep.Start)}
      >
        {({ values, submitForm }) => (
          <>
            <AddPaymentMethodModal
              open={showPaymentMethodModal}
              onClose={() => setShowPaymentMethodModal(false)}
              onManualClose={() => setBuyInProgress(false)}
              onSuccess={(paymentSourceId) => {
                // update formik paymentSourceId with newly created id
                // formikRef.current?.setFieldValue does not work :-(
                // eslint-disable-next-line no-param-reassign
                values.finishPurchase.paymentSourceId = paymentSourceId;

                // submit the form to buy if we came via the buy button
                if (paymentMethodModalVia === 'submit') {
                  onSubmit(values, SubmitStep.PaymentGood);
                }
              }}
              promotion={paymentMethodPromotion}
              onError={(error) => setFlashMessage(error, 'danger')}
              buttonText={(() => {
                if (paymentMethodModalVia === 'link') {
                  return 'Submit';
                }
                if (hasMultipleShipments) {
                  return 'Submit & Buy Labels';
                }
                return 'Submit & Buy Label';
              })()}
              origin={
                paymentMethodPromotion === 'plaidThreePercent'
                  ? 'buy_page_three_percent_promo'
                  : 'buy_page'
              }
            />
            <UspsAddressCorrectionModal
              open={showUspsAddressCorrectionModal}
              onCancel={() => {
                setBuyInProgress(false);
                setShowUspsAddressCorrectionModal(false);
              }}
              onAccountCreated={() => {
                setShowUspsAddressCorrectionModal(false);
                onSubmit(values, SubmitStep.UspsGood);
              }}
            />
            <UpsAccountModal
              buttonText={`Accept & Buy ${
                data && data.batch.numShipments > 1 ? 'Labels' : 'Label'
              }`}
              headline={upsAccountModalHeadline}
              open={showUpsAccountModal}
              onCancel={() => {
                setBuyInProgress(false);
                setShowUpsAccountModal(false);
              }}
              onAccountCreatedOrUpdated={() => {
                setShowUpsAccountModal(false);
                onSubmit(values, SubmitStep.UpsGood);
              }}
            />
            {(() => {
              // Show loading animation when there is a running background process, but only if we are handling multiple shipments.
              if (runningProcess && hasMultipleShipments) {
                return <LoadingAnimation runningProcess={runningProcess} />;
              }

              // Show spinner while re-rating
              if (runningProcess || rerateInProgress) {
                return <PageLoading />;
              }

              return (
                <Row>
                  <Col md={12}>
                    <Styled.TitleWrapper>
                      {hasMultipleShipments ? (
                        <BatchTitle
                          value={data.batch.title}
                          onUpdate={(title) => {
                            updateBatchTitle({
                              variables: {
                                id,
                                title,
                              },
                              optimisticResponse: {
                                updateBatchTitle: {
                                  __typename: 'Batch',
                                  id,
                                  title,
                                },
                              },
                            });
                          }}
                        />
                      ) : (
                        <RecipientAddress
                          isReturnLabel={data.batch.shipments[0].isReturnLabel}
                          recipientAddress={data.batch.shipments[0].recipientAddress}
                          countryMap={countryMap}
                        />
                      )}
                    </Styled.TitleWrapper>
                  </Col>
                  <Col md={12}>
                    <ShipmentDetailsBox batch={data.batch} countryMap={countryMap} />
                  </Col>
                  <Col md={12}>
                    <RateGroupsSubform<keyof BuyFormValues>
                      namespace="summaryIds"
                      rateGroups={data.batch.rateGroups}
                      user={data.user}
                      company={data.company}
                      shipmentCount={data.batch.numShipments}
                      packageDimensionsAndWeight={{
                        dimensionX: data.batch.packageSummary.packagePreset.dimensionX,
                        dimensionY: data.batch.packageSummary.packagePreset.dimensionY,
                        dimensionZ: data.batch.packageSummary.packagePreset.dimensionZ,
                        weight: data.batch.packageSummary.packagePreset.weight,
                      }}
                      onChange={onRateGroupsChange}
                      onMerchantCreated={() => {
                        // Rerate with current ship date
                        performRerate(data.batch.id, values.shipDate.date, values.summaryIds);
                      }}
                    />
                  </Col>
                  <Col md={12}>
                    <Label>Finish Purchase</Label>
                    <Form>
                      <FinishPurchaseSubform<keyof BuyFormValues>
                        promotion={paymentMethodPromotion}
                        namespace="finishPurchase"
                        settingRows={[
                          <ShipDateSettingRow<keyof BuyFormValues>
                            key="ship-date"
                            namespace="shipDate"
                            availableShipDates={availableShipDates}
                            onShipDateChange={(newShipDate) => {
                              // A batch needs to be re-rated whenever the current and requested ship date don't match
                              if (!isEqualDate(values.shipDate.date, newShipDate)) {
                                performRerate(data.batch.id, newShipDate, values.summaryIds);
                              }
                            }}
                          />,
                          data.batch.emailNotificationPossible ? (
                            <NotifyRecipientsSettingRow<keyof BuyFormValues>
                              key="notify-recipients"
                              namespace="notifyRecipients"
                              defaultEmailTemplateId={data.company.settings.defaultEmailTemplateId}
                              mailTemplates={data.company.mailTemplates}
                            />
                          ) : null,
                        ]}
                        totalCost={totalCost}
                        accountBalance={data.company.accountBalance}
                        defaultChargeAmount={data.company.settings.defaultChargeAmount}
                        paymentSources={data.company.paymentSources}
                        onAddPaymentMethodClick={() => {
                          setPaymentMethodModalVia('link');
                          setShowPaymentMethodModal(true);
                        }}
                      />
                      <Row>
                        <Col md={12}>
                          <ShipmentFlowPageFooter
                            inProgress={buyInProgress || rerateInProgress || plaidInProgress}
                            nextStepTitle={hasMultipleShipments ? 'Buy Labels' : 'Buy Label'}
                            onNextStep={submitForm}
                            onPrevStep={async () => {
                              if (data.batch.dataSource !== 'SPREADSHEET') {
                                await deleteBatch({ variables: { id } });
                                // The location we navigate back to from this page when stepping back / cancelling the workflow via bridge
                                // NOTE: navigating here instructs the backend to prefill the form on the previous page!
                                navigateOrHref('rates', `/shipment/newfrombatch?batch_id=${id}`);
                              } else {
                                // In the case of spreadsheets, the legacy backend uses the old batch, in all other cases the batch is deleted
                                await setBatchToNew({ variables: { id } });
                                navigateOrHref(`/upload/new/${id}`, `/upload/new?id=${id}`);
                              }
                            }}
                            cancelTitle={
                              hasMultipleShipments
                                ? 'Cancel & Delete Labels'
                                : 'Cancel & Delete Label'
                            }
                            cancelConfirmationTitle={
                              hasMultipleShipments ? 'Delete Labels?' : 'Delete Label?'
                            }
                            onCancel={async () => {
                              try {
                                setBackLoading(true);
                                await deleteBatch({ variables: { id } });
                              } catch {
                                setBackLoading(false);
                              } finally {
                                navigateOrHref('/ship');
                              }
                            }}
                          />
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              );
            })()}
          </>
        )}
      </Formik>
    </Styled.PageContainer>
  );
}
