import { useMutation } from '@apollo/client';
import { gql } from '../../gql';
import batchDetailsPageQuery from '../queries/batchDetailsPage';

export const createScanformByBatchMutation = gql(/* GraphQL */ `
  mutation CreateScanformByBatchMutation($batchId: ID!) {
    createScanformByBatch(batchId: $batchId) {
      batchId
      canCreateScanform
      scanformUrl
    }
  }
`);

export const useCreateScanformByBatchMutation = () =>
  useMutation(createScanformByBatchMutation, {
    // update scanform url in batch and download statuses
    update(cache, { data }) {
      if (!data) {
        return;
      }
      const { batchId, canCreateScanform, scanformUrl } = data.createScanformByBatch;
      cache.updateQuery(
        {
          query: batchDetailsPageQuery,
          variables: { batchId },
        },
        (cacheResult) => {
          if (!cacheResult) {
            return undefined;
          }
          return {
            ...cacheResult,
            batch: {
              ...cacheResult.batch,
              canCreateScanform,
              scanformUrl,
            },
          };
        },
      );
    },
  });
