import ProgressButton from '../../../../form/ProgressButton';
import { ButtonSize } from '../../../../form/Button';

type SignupSubmitButtonProps = {
  loading: boolean;
  label?: string;
  size?: ButtonSize;
};

export default function SignupSubmitButton({
  loading = false,
  label = 'Create your FREE account',
  size = 'xLarge',
}: SignupSubmitButtonProps) {
  return (
    <ProgressButton type="submit" variant="info" size={size} fullWidth progress={loading}>
      {label}
    </ProgressButton>
  );
}
