import { useState } from 'react';
import { COLOR } from '../../styles/colors';
import formatCurrency from '../../utils/formatCurrency';
import ServiceSummaryTable from './StyledServiceSummaryTable';
import { DATE_FORMAT } from '../../constants';
import Link, { LinkButton } from '../Link';
import ScheduleEmailNotificationModal from '../modals/ScheduleEmailNotificationModal';
import IntercomArticleLink, { IntercomArticleIconLink } from '../IntercomArticleLink';
import DisputeAdjustmentModal from '../modals/DisputeAdjustmentModal';
import useDateInUserTimezone from '../../hooks/useDateInUserTimezone';
import TotalCostRow from './TotalCostRow';
import ShipDateRow from './ShipDateRow';
import HeaderRow from './HeaderRow';
import EmailNotificationRow from './ShipmentEmailNotificationRow';
import ServiceSummaryPrices from './ServiceSummaryPrices';
import ServiceSummaryServiceTitle, { NullableTitle } from './ShipmentServiceSummaryServiceTitle';
import { Address } from '../../utils/formatAddress';
import { ShipmentStatus } from '../../gql/graphql';
import useIsFeatureFlagActive from '../../hooks/useIsFeatureFlagActive';

type Surcharge = {
  clientPriceOriginal: number;
  clientPrice: number;
  apiKey: string;
  title: string;
  helpLink: string;
};

type Adjustment = {
  id: string;
  disputeStatus: string;
  eligibilityIssueMessage: string;
  notes: ReadonlyArray<string>;
  createdAt: string;
  disputedAt: string | null;
  processedAt: string;
  disputeProcessedAt: string | null;
};
type ShipmentServiceSummaryTableProps = {
  batch: {
    numShipments: number;
    emailNotificationPossible: boolean;
    dataSource: string;
    emailNotificationSent: boolean;
    id: string;
    shipDate: string;
    notifyRecipientsDate: string | null;
    emailNotificationTemplate: { id: string } | null;
    shipments: ReadonlyArray<{
      id: string;
      surcharges: ReadonlyArray<Surcharge> | null;
      adjustments: ReadonlyArray<Adjustment> | null;
      carrierKey: string;
      status: ShipmentStatus;
      disputableAdjustmentsCount: number;
      insuredValueRequested: number;
      deliveryEstimation: {
        arrivalLocalDateTime: string | null;
        pickupLocalDateTime: string | null;
      } | null;
      recipientAddress: Address;
      baseClientPriceOriginal: number | null;
      baseClientPrice: number | null;
      totalClientPriceOriginal: number | null;
      totalClientPrice: number | null;
      order: { platform: { additionalTrackingEmailCopy: string } } | null;
      trackingNr: string | null;
      mailClassKey: string | null;
      mailClass: NullableTitle;
      mailClassOriginal: NullableTitle;
      zoneOriginal: string | null;
      zone: string | null;
    }>;
  };
};

export default function ShipmentServiceSummaryTable({ batch }: ShipmentServiceSummaryTableProps) {
  const [scheduleEmailNotificationModalOpen, setScheduleEmailNotificationModalOpen] =
    useState<boolean>(false);
  const [isDisputeModalOpen, setIsDisputeModalOpen] = useState(false);
  const { formatDate } = useDateInUserTimezone();
  const isUPSCInsuranceActive = useIsFeatureFlagActive('Pp.feature.upsc_insurance');
  const shipment = batch.shipments[0]; // we query only the one shipment in the batch that is relevant for this page, so we can name the shipment as such here.

  if (!shipment) return null;

  const isShipmentRefunded = shipment.status === 'REFUNDED';

  // TODO: this currently checks if the shipment contains "InsuredMail", which is the key for SHIPSURANCE insured mail.
  // The UPSC insurance surcharge will most likely be listed with a separate, new key.
  // This predicate should check for this new key as well, as it is needed to decide
  // if the link to the carrier liability portal should be shown next to the service title or not.
  // So in the future, the predicate would look something like this:
  // shipment.surcharges?.some(({apiKey}) => apiKey === 'InsuredMail' || apiKey === "UPSCInsuredMail" ), // whatever the key ends up being
  const shipmentContainsInsuranceSurcharge = Boolean(
    shipment.surcharges?.some(({ apiKey }) => apiKey === 'InsuredMail'),
  );
  const { disputableAdjustmentsCount } = shipment;

  const renderDeliveryInformation = () => {
    const { deliveryEstimation } = shipment;
    if (shipment.recipientAddress.countryCode !== 'US') return false;

    if (!deliveryEstimation?.arrivalLocalDateTime || !deliveryEstimation?.pickupLocalDateTime) {
      return null;
    }
    const arrivalDate = formatDate(
      'local',
      deliveryEstimation.arrivalLocalDateTime,
      DATE_FORMAT.usDate,
    );
    const arrivalTime = formatDate(
      'local',
      deliveryEstimation.arrivalLocalDateTime,
      DATE_FORMAT.time12NoLeadingZero,
    );
    const pickupLocalDateTime = formatDate(
      'local',
      deliveryEstimation.pickupLocalDateTime,
      DATE_FORMAT.usDate,
    );
    if (arrivalDate.length === 0 || arrivalTime.length === 0 || pickupLocalDateTime.length === 0) {
      return null;
    }
    return (
      <ServiceSummaryTable.SubText>
        {`Estimated Delivery ${arrivalDate} by ${arrivalTime} if shipped on ${pickupLocalDateTime}`}
      </ServiceSummaryTable.SubText>
    );
  };

  const renderAdjustmentsNotes = (adjustments: ReadonlyArray<Adjustment> | null) => {
    if (!adjustments || !adjustments.length) return false;

    const carrier = shipment.carrierKey.toUpperCase();

    return (
      <>
        {adjustments.map((adjustment) => {
          const { disputeStatus, eligibilityIssueMessage, notes } = adjustment;
          const [createdAt, disputedAt, disputeProcessedAt] = [
            adjustment.createdAt,
            adjustment.disputedAt,
            adjustment.disputeProcessedAt,
          ].map((date) =>
            formatDate('UTC', date ?? 'now', `${DATE_FORMAT.dayOfWeek}, ${DATE_FORMAT.usDate}`),
          );
          return (
            <div key={adjustment.id} data-testid="adjustment">
              <Link
                bridgeHref={`/reports/carrieradjustment?shipment_id=${shipment.id}`}
                to={`/reports/carrieradjustment?shipment_id=${shipment.id}`}
              >
                {`${carrier} adjusted your shipment on ${createdAt}:`}
              </Link>
              {notes.map((note, idx) => (
                // we have no better identifying property other than the array key - thus, avoid complaints here!
                // eslint-disable-next-line react/no-array-index-key
                <ServiceSummaryTable.AdjustmentNote key={`note-${idx}`}>
                  {note}
                </ServiceSummaryTable.AdjustmentNote>
              ))}
              {disputeStatus === 'DISPUTE_APPROVED' && `Dispute approved on ${disputeProcessedAt}`}
              {disputeStatus === 'DISPUTE_DENIED' && `Dispute denied on ${disputeProcessedAt}`}
              {disputeStatus === 'DISPUTE_PENDING' &&
                `Adjustment disputed on ${disputedAt}, pending ${carrier} approval`}
              {disputeStatus === 'UNDISPUTED' &&
                eligibilityIssueMessage &&
                `${eligibilityIssueMessage}`}
            </div>
          );
        })}
        {disputableAdjustmentsCount > 0 && (
          <LinkButton onClick={() => setIsDisputeModalOpen(true)}>
            {disputableAdjustmentsCount > 1 ? 'Dispute all adjustments' : 'Dispute this adjustment'}
          </LinkButton>
        )}
      </>
    );
  };

  const renderShipmentSurcharges = (surcharges: ReadonlyArray<Surcharge> | null) => {
    if (!surcharges) return false;
    return surcharges.map((surcharge) => {
      const isNowMoreExpensive = surcharge.clientPriceOriginal < surcharge.clientPrice;
      const isInsuredMailSurcharge = surcharge.apiKey === 'InsuredMail';

      // special row: insured mail surcharge
      if (isInsuredMailSurcharge) {
        return (
          <ServiceSummaryTable.Row key={surcharge.title} white>
            <ServiceSummaryTable.Col>
              <ServiceSummaryTable.SurchargeTitle
                color={isNowMoreExpensive ? COLOR.red : 'inherit'}
              >
                {`${surcharge.title} (${formatCurrency(
                  shipment.insuredValueRequested,
                )} insured value)`}
              </ServiceSummaryTable.SurchargeTitle>{' '}
              <IntercomArticleLink href="https://support.pirateship.com/en/articles/1068439-how-do-i-file-an-insurance-claim">
                File an insurance claim
              </IntercomArticleLink>
            </ServiceSummaryTable.Col>
            <ServiceSummaryTable.Col alignRight>
              <ServiceSummaryPrices
                previousPrice={surcharge.clientPriceOriginal}
                currentPrice={isShipmentRefunded ? 0 : surcharge.clientPrice}
              />
            </ServiceSummaryTable.Col>
          </ServiceSummaryTable.Row>
        );
      }

      return (
        <ServiceSummaryTable.Row key={surcharge.title} white>
          <ServiceSummaryTable.Col>
            <ServiceSummaryTable.SurchargeTitle color={isNowMoreExpensive ? COLOR.red : 'inherit'}>
              {surcharge.title}
            </ServiceSummaryTable.SurchargeTitle>
            {surcharge.helpLink && (
              <IntercomArticleIconLink
                url={surcharge.helpLink}
                title="Surcharges Help"
                data-testid={`help-link-${surcharge.apiKey}`}
              />
            )}
          </ServiceSummaryTable.Col>
          <ServiceSummaryTable.Col alignRight>
            <ServiceSummaryPrices
              previousPrice={surcharge.clientPriceOriginal}
              currentPrice={isShipmentRefunded ? 0 : surcharge.clientPrice}
            />
          </ServiceSummaryTable.Col>
        </ServiceSummaryTable.Row>
      );
    });
  };

  return (
    <>
      {shipment.adjustments && shipment.adjustments.length > 0 && (
        <DisputeAdjustmentModal
          open={isDisputeModalOpen}
          shipment={shipment}
          closeModal={() => setIsDisputeModalOpen(false)}
        />
      )}
      <ScheduleEmailNotificationModal
        batchId={batch.id}
        selectedTemplateId={batch.emailNotificationTemplate?.id}
        open={scheduleEmailNotificationModalOpen}
        onClose={() => setScheduleEmailNotificationModalOpen(false)}
        notifyRecipientsDate={batch.notifyRecipientsDate}
      />
      <ServiceSummaryTable.Content data-testid="summary-table">
        <HeaderRow />
        <ServiceSummaryTable.Row white>
          <ServiceSummaryTable.Col>
            <ServiceSummaryTable.Spacer>
              <div>
                <ServiceSummaryServiceTitle
                  shipment={shipment}
                  isUPSCInsuranceActive={isUPSCInsuranceActive}
                  shipmentContainsInsuranceSurcharge={shipmentContainsInsuranceSurcharge}
                />
                {renderDeliveryInformation()}
              </div>
              {renderAdjustmentsNotes(shipment.adjustments)}
            </ServiceSummaryTable.Spacer>
          </ServiceSummaryTable.Col>
          <ServiceSummaryTable.Col alignRight>
            <ServiceSummaryPrices
              previousPrice={shipment.baseClientPriceOriginal}
              currentPrice={isShipmentRefunded ? 0 : shipment.baseClientPrice}
            />
          </ServiceSummaryTable.Col>
        </ServiceSummaryTable.Row>
        {renderShipmentSurcharges(shipment.surcharges)}
        <TotalCostRow
          previousCost={isShipmentRefunded ? 0 : shipment.totalClientPriceOriginal}
          currentCost={isShipmentRefunded ? 0 : shipment.totalClientPrice}
          status={shipment.status}
        />
        <ShipDateRow shipDate={batch.shipDate} />
        <EmailNotificationRow
          onClickDate={() => setScheduleEmailNotificationModalOpen(true)}
          additionalTrackingEmailCopy={shipment.order?.platform.additionalTrackingEmailCopy}
          batch={batch}
          shipment={shipment}
        />
      </ServiceSummaryTable.Content>
    </>
  );
}
