import { useState, useCallback, useEffect, useRef, ReactNode } from 'react';
import styled from '@emotion/styled';
import { COLOR, GREYSCALE } from '../../styles/colors';
import withOpacity from '../../utils/withOpacity';
import { BORDER_WIDTH } from '../../styles/borders';
import { SPACING } from '../../styles/spacing';
import { MEDIA_QUERY } from '../../styles/breakpoints';
import { TYPOGRAPHY } from '../../styles/typography';
import Shimmer from '../loading/Shimmer';

const DRAG_ENABLE_DISTANCE_PX = 10;
const DRAG_ENABLE_HOLD_MS = 100;

type ToggleState = {
  checked?: boolean;
  dragging?: boolean;
};

const Styled = {
  Toggle: styled.div<ToggleState>`
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    width: 90px;
    min-width: 90px;
    height: 50px;
    border: ${BORDER_WIDTH.sm} solid;
    border-radius: 50px;
    transition: background-color 100ms ease-out;
    background-color: ${(props) => (props.checked ? COLOR.blue : GREYSCALE.grey30)};
    border-color: ${(props) => (props.checked ? COLOR.blueButtonAccent : GREYSCALE.grey30)};
    user-select: none;
    cursor: pointer;
    > input {
      display: none;
      // for acceptance test needed
      position: relative;
      z-index: 3000;
    }
  `,
  Knob: styled.span<ToggleState>`
    position: absolute;
    width: ${(props) => (props.dragging ? '56px' : '46px')};
    height: 46px;
    border-radius: 46px;
    transition: all 100ms ease-out;
    top: 0;
    left: ${(props) => {
      if (props.checked) {
        if (props.dragging) return '30px';
        return '40px';
      }
      return '0';
    }};
    background: ${GREYSCALE.grey00}
      linear-gradient(to bottom, ${GREYSCALE.white}, ${GREYSCALE.grey20});
    box-shadow: -1px 0 2px ${withOpacity(GREYSCALE.black, 0.15)};
  `,
  ToggleWrapper: styled.div`
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    margin-bottom: ${SPACING.xl};

    @media (max-width: ${MEDIA_QUERY.smMax}) {
      align-items: center;
    }
  `,
  ToggleLabelWrapper: styled.div`
    margin-left: ${SPACING.md};
  `,
  ToggleLabel: styled.div`
    display: inline-block;
    padding: ${SPACING.xs} ${SPACING.none};
    font-size: ${TYPOGRAPHY.body.fontSize};
    font-weight: ${TYPOGRAPHY.fontWeight.medium};
    color: ${GREYSCALE.grey80};
  `,
  ToggleSublabel: styled.div`
    font-size: ${TYPOGRAPHY.fontSize.sm};
  `,
};

// Prefer to use pointer events, but fall back to touch or mouse
const events = (() => {
  if (window.PointerEvent) {
    return {
      down: 'pointerdown',
      move: 'pointermove',
      up: 'pointerup',
      cancel: 'pointercancel',
    };
  }

  if ('ontouchstart' in window) {
    return {
      down: 'touchstart',
      move: 'touchmove',
      up: 'touchend',
      cancel: 'touchcancel',
    };
  }

  return {
    down: 'mousedown',
    move: 'mousemove',
    up: 'mouseup',
    cancel: undefined,
  };
})() as Record<string, keyof DocumentEventMap | undefined>;

// Retrieve clientX property from event
function getClientX(event: Event) {
  if (window.PointerEvent && event instanceof PointerEvent) {
    return event.clientX;
  }

  if (window.TouchEvent && event instanceof TouchEvent) {
    // Use first touch to get value
    if (event.touches.length > 0) {
      return event.touches[0].clientX;
    }
  }

  if (window.MouseEvent && event instanceof MouseEvent) {
    return event.clientX;
  }

  return undefined;
}

export type ToggleProps = Omit<JSX.IntrinsicElements['input'], 'onChange'> & {
  onChange?: (checked: boolean) => void;
  loading?: boolean;
  label?: ReactNode;
  sublabel?: ReactNode;
};

export default function Toggle({
  checked,
  onChange,
  loading = false,
  label,
  sublabel,
  ...others
}: ToggleProps) {
  const toggleRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<number | undefined>(undefined);
  const [localChecked, setLocalChecked] = useState(checked || false);
  const [down, setDown] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [startingX, setStartingX] = useState<number>();
  const [wasDragged, setWasDragged] = useState(false);

  // Update local state when outer state is changed
  useEffect(() => setLocalChecked(checked || false), [checked]);

  // Event handlers
  const handleDown = useCallback((event: Event) => {
    const clientX = getClientX(event);

    setDown(true);
    setStartingX(clientX);
    setWasDragged(false);
  }, []);
  const handleMove = useCallback(
    (event: Event) => {
      const clientX = getClientX(event);

      if (startingX !== undefined && clientX !== undefined) {
        const distance = clientX - startingX;

        // Detect as dragging when min distance was reached
        if (Math.abs(distance) >= DRAG_ENABLE_DISTANCE_PX) {
          setDragging(true);
        }

        // Update floating checked state (will be committed when drag is released)
        if (localChecked && distance <= -DRAG_ENABLE_DISTANCE_PX) {
          setWasDragged(true);
          setLocalChecked(false);
        } else if (!localChecked && distance >= DRAG_ENABLE_DISTANCE_PX) {
          setWasDragged(true);
          setLocalChecked(true);
        }
      }
    },
    [localChecked, startingX],
  );
  const handleUp = useCallback(() => {
    setDown(false);
    setDragging(false);

    if (onChange) {
      if (!wasDragged) {
        onChange(!checked);
      } else {
        onChange(localChecked);
      }
    }
  }, [checked, localChecked, wasDragged, onChange]);
  const handleCancel = useCallback(() => {
    setLocalChecked(checked || false);
    setDown(false);
    setDragging(false);
  }, [checked]);

  // Detect as dragging after holding the knob for a certain amount of time
  useEffect(() => {
    if (down) {
      timeoutRef.current = window.setTimeout(() => setDragging(true), DRAG_ENABLE_HOLD_MS);
    } else {
      window.clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }

    return () => {
      window.clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    };
  }, [down]);

  // Down event handler
  useEffect(() => {
    const node = toggleRef.current;

    if (node && events.down) {
      node.addEventListener(events.down, handleDown);
    }

    return () => {
      if (node && events.down) {
        node.removeEventListener(events.down, handleDown);
      }
    };
  }, [handleDown]);

  // Move, Up, Cancel event handlers
  useEffect(() => {
    if (down) {
      if (events.move) document.addEventListener(events.move, handleMove);
      if (events.up) document.addEventListener(events.up, handleUp);
      if (events.cancel) document.addEventListener(events.cancel, handleCancel);
    } else {
      if (events.move) document.removeEventListener(events.move, handleMove);
      if (events.up) document.removeEventListener(events.up, handleUp);
      if (events.cancel) document.removeEventListener(events.cancel, handleCancel);
    }

    return () => {
      if (events.move) document.removeEventListener(events.move, handleMove);
      if (events.up) document.removeEventListener(events.up, handleUp);
      if (events.cancel) document.removeEventListener(events.cancel, handleCancel);
    };
  }, [down, handleMove, handleUp, handleCancel]);

  if (loading) {
    return <Shimmer.Rectangle />;
  }

  return (
    <Styled.ToggleWrapper>
      <Styled.Toggle ref={toggleRef} checked={localChecked}>
        <Styled.Knob checked={localChecked} dragging={dragging} />
        <input type="checkbox" checked={checked} readOnly {...others} />
      </Styled.Toggle>
      <Styled.ToggleLabelWrapper>
        {label && <Styled.ToggleLabel>{label}</Styled.ToggleLabel>}
        {sublabel && <Styled.ToggleSublabel>{sublabel}</Styled.ToggleSublabel>}
      </Styled.ToggleLabelWrapper>
    </Styled.ToggleWrapper>
  );
}
