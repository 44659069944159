import { useMutation } from '@apollo/client';
import { gql } from '../../gql';

export const createPlaidAccessTokenMutation = gql(/* GraphQL */ `
  mutation CreatePlaidAccessTokenMutation($publicToken: String!, $handle: String!) {
    createPlaidAccessToken(publicToken: $publicToken, handle: $handle) {
      handle
      paymentSource {
        ...PaymentSourceFragment
      }
      mask
      name
    }
  }
`);

export const useCreatePlaidAccessTokenMutation = () =>
  useMutation(createPlaidAccessTokenMutation, {
    // Add newly created entry to list
    update(cache, { data }) {
      if (!data) {
        return;
      }
      cache.updateQuery(
        {
          query: gql(/* GraphQL */ `
            query UpdatePlaidPaymentSourceAfterValidationQuery {
              company {
                paymentSources {
                  ...PaymentSourceFragment
                }
              }
            }
          `),
        },
        (cacheResult) => {
          if (!cacheResult || !data.createPlaidAccessToken.paymentSource) {
            return undefined;
          }

          return {
            company: {
              ...cacheResult.company,
              paymentSources: [
                ...cacheResult.company.paymentSources,
                data.createPlaidAccessToken.paymentSource,
              ],
            },
          };
        },
      );
    },
  });
