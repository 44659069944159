import AddressSubform from './AddressSubform';
import { NamespacedSubform } from './types';

const billingAddressLabels = {
  company: 'Legal Business Name (optional)',
  address1: 'Physical Street Address',
};

type BillingAddressSubformProps<NS extends string> = {
  carrier: 'ups' | 'usps';
} & NamespacedSubform<NS>;

export default function BillingAddressSubform<NS extends string>({
  namespace,
  carrier,
}: BillingAddressSubformProps<NS>) {
  return (
    <AddressSubform<NS>
      namespace={namespace}
      customLabels={billingAddressLabels}
      nameRequired
      includePhone={carrier === 'usps'}
    />
  );
}
