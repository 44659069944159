import styled from '@emotion/styled';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import * as FileSaver from 'file-saver';
import { GREYSCALE } from '../../../styles/colors';
import { TYPOGRAPHY } from '../../../styles/typography';
import Button from '../../form/Button';
import { Col, PageContainer, Row } from '../../layout/Grid';
import Link from '../../Link';
import RecipientAddress from '../../RecipientAddress';
import ServiceSummaryTable from '../../serviceSummaryTable/ShipmentServiceSummaryTable';
import ShipmentStageDots from '../../ShipmentStageDots';
import { DATE_FORMAT } from '../../../constants';
import useNavigateOrHref from '../../../hooks/useNavigateOrHref';
import ShipmentDetailsBox from '../../shipmentDetailsBox/ShipmentDetailsPageShipmentDetailsBox';
import ShipmentMap from '../../ShipmentMap';
import { ShipmentStatus } from '../../../gql/graphql';
import PageLoading from '../../loading/PageLoading';
import { SPACING } from '../../../styles/spacing';
import useDateInUserTimezone from '../../../hooks/useDateInUserTimezone';
import { useRefundShipmentMutation } from '../../../operations/mutations/refundShipment';
import { addFlashMessage, setFlashMessage } from '../../../apollo/cache/flashMessages';
import ShipmentRefundModal from '../../modals/ShipmentRefundModal';
import { useDataGridExportStatusLazyQuery } from '../../../operations/queries/dataGridExportStatus';
import ProgressButton from '../../form/ProgressButton';
import PageHeader from '../../layout/PageHeader';
import { getShipmentRefundSuccessMessage } from '../../../utils/getRefundShipmentMessage';
import ShipmentPrintModal from '../../modals/print/ShipmentPrintModal';
import useMappedCountries from '../../../hooks/useMappedCountries';
import shipmentDetailsPageQuery from '../../../operations/queries/shipmentDetailsPage';

const unsuccessfulStatuses: ShipmentStatus[] = [
  'INVALID',
  'PURCHASE_FAILED',
  'RATE_FAILED',
  'VALIDATION_FAILED',
  'REFUND_FAILED',
  'REFUND_DECLINED',
];

const Styled = {
  TransactionDateText: styled.div`
    color: ${GREYSCALE.grey50};
    font-size: ${TYPOGRAPHY.fontSize.sm};
    padding-bottom: ${SPACING.sm};
  `,
  BatchLinkWrapper: styled.div`
    padding-bottom: ${SPACING.sm};
  `,
  BatchLink: styled(Link)`
    font-size: ${TYPOGRAPHY.fontSize.sm};
  `,
  PrintButtonWrapper: styled.div`
    align-self: end;
    display: flex;
  `,
  HiddenOnPrint: styled.div`
    @media print {
      display: none;
    }
  `,
};

type ShipmentDetailsPageProps = {
  bridgeShipmentId?: string; // only used if we are coming from the bridge
  bridgeBatchId?: string; // only used if we are coming from the bridge
};

export default function ShipmentDetailsPage({
  bridgeShipmentId,
  bridgeBatchId,
}: ShipmentDetailsPageProps) {
  const params = useParams();
  const shipmentId = bridgeShipmentId ?? params.shipmentId ?? '0'; // should never default to 0
  const batchId = bridgeBatchId ?? params.batchId ?? '0'; // should never default to 0
  const [refundModalOpen, setRefundModalOpen] = useState(false);
  const navigateOrHref = useNavigateOrHref();
  const [showShipmentPrintModal, setShowPrintModal] = useState(false);
  const { data, loading: batchLoading } = useQuery(shipmentDetailsPageQuery, {
    variables: { batchId, shipmentId },
  });
  const countryMap = useMappedCountries();
  const { batch, company } = data ?? { batch: null, company: null };
  const { formatDate } = useDateInUserTimezone();
  const [refundShipment, { loading: loadingRefundShipment }] = useRefundShipmentMutation();
  const [getDataGridExportStatus, { data: exportStatusData, loading: exportStatusLoading }] =
    useDataGridExportStatusLazyQuery();

  useEffect(() => {
    if (exportStatusData?.shipment.trackingDataExportUrl.url) {
      FileSaver.saveAs(
        exportStatusData.shipment.trackingDataExportUrl.url,
        `${exportStatusData.shipment.trackingDataExportUrl.filename}`,
      );
    }
  }, [exportStatusData]);

  if (!shipmentId || !batchId) {
    return null;
  }

  if (batchLoading) {
    return (
      <PageContainer>
        <PageLoading />
      </PageContainer>
    );
  }
  const shipment = batch?.shipments[0];
  if (!batch || !shipment) {
    navigateOrHref(`/batch/${batchId}`);
    return (
      <PageContainer>
        <PageLoading />
      </PageContainer>
    );
  }

  const handleRefund = async () => {
    if (!shipmentId) {
      return;
    }
    try {
      await refundShipment({
        variables: {
          shipmentId,
        },
      });
    } catch (error: any) {
      setFlashMessage(error, 'danger');
    }
    setRefundModalOpen(false);
    addFlashMessage(
      getShipmentRefundSuccessMessage(
        shipment.canInstantRefundShipment,
        shipment.carrierKey === 'ups',
      ),
      'success',
    );
  };

  return (
    <PageContainer>
      <ShipmentPrintModal
        open={showShipmentPrintModal}
        onClose={() => {
          setShowPrintModal(false);
        }}
        shipmentId={shipmentId}
      />
      <ShipmentRefundModal
        open={refundModalOpen}
        onCancel={() => setRefundModalOpen(false)}
        onConfirm={handleRefund}
        confirmationButtonProgress={loadingRefundShipment}
        canInstantRefundShipment={shipment.canInstantRefundShipment}
        isUpsShipment={shipment.carrierKey === 'ups'}
      />
      <Row>
        <Col>
          <PageHeader
            actionsTopOnMobile
            title={
              <div>
                <Styled.TransactionDateText>
                  {formatDate(
                    'UTC',
                    batch.createdAt,
                    `${DATE_FORMAT.dayOfWeek}, ${DATE_FORMAT.usDate} ${DATE_FORMAT.time12NoLeadingZero} ${DATE_FORMAT.timezone}`,
                  )}
                </Styled.TransactionDateText>
                {batch.numShipments > 1 && (
                  <Styled.BatchLinkWrapper>
                    {/* We shouldn't add a bridgeHref, as we want the wrapping react router to take care of our navigation */}
                    <Styled.BatchLink to={`/batch/${batch.id}`}>{batch.title}</Styled.BatchLink>
                  </Styled.BatchLinkWrapper>
                )}
                <RecipientAddress
                  recipientAddress={shipment.recipientAddress}
                  isReturnLabel={batch.packageSummary.packagePreset.returnLabelFlag}
                  countryMap={countryMap}
                />
              </div>
            }
            rightSideActions={[
              shipment.canPrint && (
                <Button
                  hideForPrint
                  key="printButton"
                  size="xLarge"
                  disabled={batch.cleanupComplete || batchLoading}
                  variant={shipment.printed || batch.cleanupComplete ? 'secondary' : 'success'}
                  onClick={() => {
                    setShowPrintModal(true);
                  }}
                >
                  {(() => {
                    if (batch.cleanupComplete) {
                      return 'Label Expired';
                    }
                    if (shipment.printed) {
                      return 'Reprint Label';
                    }
                    return 'Print Label';
                  })()}
                </Button>
              ),
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ShipmentDetailsBox
            batch={batch}
            countryMap={countryMap}
            accountBalance={company.accountBalance}
          />
        </Col>
      </Row>
      {!unsuccessfulStatuses.includes(shipment.status) && (
        <>
          <Styled.HiddenOnPrint>
            <Row>
              <Col md={12} spaceBelow>
                <ShipmentStageDots stageDots={shipment.stageDots} />
              </Col>
            </Row>
            <Row>
              <Col md={12} spaceBelow>
                <ShipmentMap shipmentId={shipment.id} />
              </Col>
            </Row>
          </Styled.HiddenOnPrint>
          <Row>
            <Col md={12} spaceBelow>
              <ServiceSummaryTable batch={batch} />
            </Col>
          </Row>
        </>
      )}
      <Styled.HiddenOnPrint>
        <Row justify="end">
          <Col md={12} lg={4} spaceBelow>
            <Button
              fullWidth
              key="duplicateButton"
              onClick={() => navigateOrHref('/ship/single', shipment.duplicateShipmentLink)}
            >
              Duplicate Shipment
            </Button>
          </Col>
          <Col md={12} lg={4} spaceBelow>
            <ProgressButton
              fullWidth
              key="exportButton"
              progress={exportStatusLoading}
              onClick={() =>
                getDataGridExportStatus({
                  variables: {
                    shipmentId,
                  },
                })
              }
            >
              Export Tracking Data
            </ProgressButton>
          </Col>
          {shipment.isShipmentRefundable && (
            <Col md={12} lg={4} spaceBelow>
              <Button
                fullWidth
                key="refundButton"
                variant="danger"
                onClick={() => setRefundModalOpen(true)}
              >
                Refund Label
              </Button>
            </Col>
          )}
        </Row>
      </Styled.HiddenOnPrint>
    </PageContainer>
  );
}
