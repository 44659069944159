import { KeyboardEvent, memo, MouseEvent, Ref, useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import { useCallbackOne as useCallback, useMemoOne as useMemo } from 'use-memo-one';
import { useApolloClient } from '@apollo/client';
import debounce from 'lodash.debounce';
import isEqual from 'lodash.isequal';
import sortBy from 'lodash.sortby';
import {
  ColumnDef,
  ColumnFiltersState,
  ColumnOrderState,
  ColumnSizingState,
  Row,
  SortingState,
  VisibilityState,
} from '@tanstack/react-table';
import { FetchPolicy } from '@apollo/client/core/watchQueryOptions';
import DataGrid, {
  DataGridImperativeHandleRef,
  DataGridProps,
  DEFAULT_COLUMN_WIDTH,
  ExportDataCallback,
  FetchDataCallback,
} from './DataGrid';
import mapColumnDefinition from './utils/mapColumnDefinition';
import convertFiltersForQuery from './utils/convertFiltersForQuery';
import convertSortingForQuery from './utils/convertSortingForQuery';
import { Action } from './types';
import useLogger from '../../hooks/useLogger';
import useReportError from '../../hooks/useReportError';
import useConnectedGridConfigQuery, {
  Column as ColumnType,
  ConnectedGridConfigQueryResult,
  RowAction,
} from './operations/queries/connectedGridConfig';
import useConnectedGridRowsLazyQuery, {
  ConnectedGridRowsQueryResult,
  GridQueryVariables,
} from './operations/queries/connectedGridRows';
import {
  ConnectedGridExportQueryResult,
  makeConnectedGridExportQuery,
} from './operations/queries/connectedGridExport';
import useConnectedGridMultiCheckOptionsLazyQuery, {
  ConnectedGridMulticheckOptionsQueryResult,
} from './operations/queries/connectedGridMultiCheckOptions';
import parseFilterValues from './utils/parseFilterValues';
import useCurrentUser from '../../hooks/useCurrentUser';
import convertColumnsForQuery from './utils/convertColumnsForQuery';
import { DataGridSettingsInput, DataGridMetaData } from '../../gql/graphql';
import { useUpdateDataGridUserSettingsMutation } from '../../operations/mutations/updateDataGridUserSettings';
import { safelyParseColumnOrder, safelyParseColumns } from './utils/safelyParseColumns';
import ContentLoading from '../loading/ContentLoading';
import constructGridQueryVariables from './utils/constructGridQueryVariables';
import { calculateDataGridCacheId } from '../../operations/queries/dataGrid';

const PERSIST_USER_SETTINGS_DELAY = 250;

const missingActionHandlers: Record<string, boolean> = {};

const sleep = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

type ConnectedDataGridQueryOptions = {
  fetchPolicy?: FetchPolicy;
};

export type ConnectedGridProps = {
  queryName: string;
  gridQueryVariables?: GridQueryVariables; // if the query itself uses variables, define their values and types here
  queryOptions?: ConnectedDataGridQueryOptions;
  onRowClick?: (row: Row<any>, event: MouseEvent | KeyboardEvent) => void;
  imperativeHandleRef?: Ref<DataGridImperativeHandleRef>; // lets you call the refetchData function from outside the grid
  rowActionHandlers?: {
    [id: string]: (row: Row<any>, event: MouseEvent) => void;
  };
  shouldExportAllColumns?: boolean;
  loading?: boolean;
  onMetaDataChanged?: (metaData: DataGridMetaData[]) => void;
  onGridReady?: () => void;
  onGridLoading?: () => void;
  onGridLoaded?: () => void;
} & Omit<
  DataGridProps<any, any>,
  | 'gridId'
  | 'columns'
  | 'data'
  | 'fetchData'
  | 'exportData'
  | 'loading'
  | 'totalItemCount'
  | 'pageCount'
  | 'searchDelay'
  | 'initialSorting'
  | 'initialPageSize'
  | 'rememberLocalFilters'
  | 'checkableIds'
>; // these props are set by the ConnectedDataGrid and should not be able to be passed from above

type ColumnStub = {
  field: string;
  [index: string]: any;
};

// given a path and an object, safely walk to the end of it and deliver the delicious goo inside
const extractGridFieldFromDataResult = (
  obj: Record<string, any> | undefined, // the data object returned by a graphql query
  path: string,
): any => {
  const pathArray = path.split(':');
  if (!obj) return undefined; // if the object is empty, return undefined
  const [nextKey, ...restPath] = pathArray;
  if (!obj[nextKey]) throw Error(`${nextKey} was not in the object`); // if the path is pointing to something not there, throw an error
  const nextObj = obj[nextKey]; // otherwise, extract the next object in the path
  if (!restPath.length) return nextObj; // if there are no upcoming path keys, return the final object here.
  return extractGridFieldFromDataResult(nextObj, restPath.join(':'));
};

function ConnectedDataGridComponent({
  queryName,
  gridQueryVariables,
  queryOptions,
  showDataOnFirstLoad = true,
  rowActionHandlers,
  initialColumnFilters,
  initialSearchTerm,
  onColumnFiltersChange,
  onSortingChange,
  onPageSizeChange,
  onColumnSizingChange,
  onColumnOrderChange,
  onColumnVisibilityChange,
  rowSelectEnabled,
  imperativeHandleRef,
  shouldExportAllColumns = false,
  loading = false,
  onMetaDataChanged,
  onGridReady,
  onGridLoading,
  onGridLoaded,
  ...gridProps
}: ConnectedGridProps) {
  const logger = useLogger();
  const reportError = useReportError();
  const apolloClient = useApolloClient();
  const [currentUser] = useCurrentUser();
  const {
    loading: configLoading,
    data: cd,
    error: columnsError,
  } = useConnectedGridConfigQuery(queryName, gridQueryVariables, {
    ...queryOptions,
    onCompleted: () => onGridReady?.(),
  });
  const [fetchRows, { loading: rowsLoading, data: rd }] = useConnectedGridRowsLazyQuery(
    queryName,
    gridQueryVariables,
    queryOptions,
  );
  const [fetchMultiCheckOptions] = useConnectedGridMultiCheckOptionsLazyQuery(
    queryName,
    gridQueryVariables,
  );
  const rowsData: ConnectedGridRowsQueryResult = extractGridFieldFromDataResult(rd, queryName);
  const configData: ConnectedGridConfigQueryResult = extractGridFieldFromDataResult(cd, queryName);

  const [userSettings, setUserSettings] = useState<DataGridSettingsInput>();

  const [showData, setShowData] = useState<boolean>(true);

  const gridId = useMemo(() => {
    if (configData) {
      return configData.id as string;
    }

    return undefined;
  }, [configData]);

  const cacheId = useMemo(() => {
    if (configData) {
      return calculateDataGridCacheId({
        id: configData.id,
        metaData: configData.metaData,
        __typename: 'DataGridResult',
      });
    }

    return undefined;
  }, [configData]);

  const [updateDataGridUserSettings] = useUpdateDataGridUserSettingsMutation(cacheId);

  // map the configData to the userSettings state when it comes in
  useEffect(() => {
    if (configData) {
      let settings: DataGridSettingsInput | undefined;

      if (configData.userSettings) {
        // Keep at least one column visible
        const columns = safelyParseColumns(configData.userSettings.columns);
        settings = {
          columns,
          columnOrder: safelyParseColumnOrder(configData.userSettings.columnOrder),
          // @ts-ignore next line
          sorting: configData.userSettings.sorting.map((sort) => ({
            field: sort.field,
            direction: sort.direction,
          })),
          pageSize: configData.userSettings.pageSize,
        };
      } else {
        // Keep at least one column visible
        const columns = safelyParseColumns(configData.columns);
        const columnOrder = safelyParseColumnOrder(
          configData.columns.map((col: { field: string }) => col.field),
        );
        settings = {
          columns,
          columnOrder,
          sorting: [],
          pageSize: 10,
        };
      }
      setUserSettings(settings);
    }
  }, [configData, queryName]);

  useEffect(() => {
    if (!rowsData) {
      return;
    }

    if (onMetaDataChanged) {
      onMetaDataChanged(rowsData.metaData);
    }
  }, [rowsData, onMetaDataChanged]);

  useEffect(() => {
    if (rowsLoading) {
      onGridLoading?.();
    } else {
      onGridLoaded?.();
    }
  }, [rowsLoading, onGridLoading, onGridLoaded]);

  const saveUserSettings = useMemo(
    () =>
      debounce(async (settings: DataGridSettingsInput) => {
        try {
          if (!gridId) {
            throw new Error('Missing gridId');
          }

          if (!cacheId) {
            throw new Error('Missing cacheId');
          }

          const validColumns = safelyParseColumns(settings.columns);
          const validColumnOrder = safelyParseColumnOrder(settings.columnOrder);
          const validSettings = {
            ...settings,
            columns: validColumns,
            columnOrder: validColumnOrder,
          };
          logger.debug('Persisting grid user settings', { settings: validSettings });
          const result = await updateDataGridUserSettings({
            variables: {
              gridId,
              settings: validSettings,
            },
            errorPolicy: 'all',
          });

          if (result.errors) {
            const combinedError = result.errors.map((error) => error.message).join('; ');
            throw new Error(combinedError);
          }
        } catch (error) {
          logger.info('Error saving grid user settings', { settings }, error as Error);
        }
      }, PERSIST_USER_SETTINGS_DELAY),
    [updateDataGridUserSettings, gridId, cacheId, logger],
  );

  const saveUserSettingsIfNotEqual = useCallback(
    (settings: DataGridSettingsInput) => {
      // Only update state when there was an actual change
      if (!isEqual(settings, userSettings)) {
        setUserSettings(settings);
        saveUserSettings(settings);
      }
    },
    [saveUserSettings, userSettings],
  );

  const columns: ColumnDef<any, any>[] = useMemo(() => {
    if (configData && currentUser) {
      const columnOverrides: Record<string, any> = {};

      // Unpack user column settings into a hash table for easy access
      if (configData.userSettings) {
        configData.userSettings.columns.forEach((col: ColumnStub) => {
          columnOverrides[col.field] = col;
        });
      }

      // Merge column base definition with user overrides
      const columnDef = configData.columns.map((col: ColumnType) => ({
        ...col,
        ...(columnOverrides[col.field] as ColumnStub),
      }));

      // Sort columns based on user preference
      let additionalColumnCount = 0;
      const sortedColumnDef = sortBy(columnDef, (col) => {
        const index = configData.userSettings?.columnOrder.indexOf(col.field);
        if (index === -1) {
          // If the column is not yet in the user settings, append it to the end
          additionalColumnCount += 1;
          return columnDef.length + additionalColumnCount;
        }
        return index;
      });
      // Map rowActions
      const actions: Action[] = (configData.rowActions || []).map((action: RowAction) => {
        const handler = rowActionHandlers?.[action.id];
        const key = `${gridId}:${action.id}`;

        // Log if handler is missing (but only once)
        if (typeof handler !== 'function' && !missingActionHandlers[key]) {
          logger.info(`Missing handler in grid '${gridId}' for action '${action.id}'`);
          missingActionHandlers[key] = true;
        }

        return {
          key: action.id,
          label: action.label,
          variant: action.variant,
          size: 'small',
          fullWidth: action.fullWidth,
          onClick: handler,
        };
      });
      // Map column definition to react-table columns
      return mapColumnDefinition({
        rowSelectEnabled,
        remoteColumns: sortedColumnDef,
        rowActions: actions,
        fetchMultiCheckOptions: async ({ column, columnFilters, globalFilter, allColumns }) => {
          const { data: mcoData } = await fetchMultiCheckOptions({
            variables: {
              searchTerm: globalFilter,
              filters: convertFiltersForQuery(
                columnFilters.filter((filter) => filter.id !== column.id),
                allColumns,
              ),
              groupByField: column.id,
              ...constructGridQueryVariables(gridQueryVariables),
            },
            ...queryOptions,
          });
          const { multiCheckOptions }: ConnectedGridMulticheckOptionsQueryResult =
            extractGridFieldFromDataResult(mcoData, queryName);
          return multiCheckOptions.map((option) => ({ ...option })); // return non-readonly version of options
        },
      });
    }

    // No data yet? Pretend we have no columns for now.
    return [];
  }, [
    configData,
    currentUser,
    rowSelectEnabled,
    rowActionHandlers,
    gridId,
    logger,
    fetchMultiCheckOptions,
    gridQueryVariables,
    queryName,
    queryOptions,
  ]);

  const initialSorting: SortingState | undefined = useMemo(() => {
    if (configData?.userSettings) {
      return configData.userSettings.sorting.map((sort: any) => ({
        id: sort.field,
        desc: sort.direction === 'DESC',
      }));
    }
    return undefined;
  }, [configData]);

  const initialPageSize: number | undefined = useMemo(() => {
    if (configData?.userSettings) {
      return configData.userSettings.pageSize;
    }
    return undefined;
  }, [configData]);

  const maxPageSize: number | undefined = useMemo(() => {
    if (configData) {
      return configData.maxPageSize;
    }
    return undefined;
  }, [configData]);

  const staticFilters: ColumnFiltersState | undefined = useMemo(
    () =>
      configData &&
      (configData.staticFilters.map((filter: any) => ({
        id: filter.field,
        value: parseFilterValues(
          [...filter.values],
          columns.find((col) => col.id === filter.field)?.meta?.dataType,
        ),
      })) as ColumnFiltersState),
    [configData, columns],
  );

  const localFiltersEnabled = useMemo(
    () => (configData && configData.localFiltersEnabled) || false,
    [configData],
  );

  const fetchData = useCallback<FetchDataCallback<any>>(
    async ({
      pagination,
      columnFilters,
      globalFilter,
      columns: columnInstances,
      sorting,
      forceNetwork,
    }) => {
      const { pageIndex, pageSize } = pagination;
      logger.debug('Fetching grid data for page index and size', {
        context: { pageIndex, pageSize },
      });
      if (!showDataOnFirstLoad && globalFilter === '' && columnFilters.length === 0) {
        setShowData(false);
        return;
      }

      // if the queryName we are using for the grid needs extra arguments, they are added to the row query here
      const querySpecificVariables = constructGridQueryVariables(gridQueryVariables);

      setShowData(true);

      await fetchRows({
        variables: {
          searchTerm: globalFilter,
          filters: convertFiltersForQuery(columnFilters, columnInstances),
          sorting: convertSortingForQuery(sorting),
          limit: pageSize,
          offset: Math.max(0, pageIndex * pageSize), // https://github.com/TanStack/table/issues/4478
          ...querySpecificVariables,
        },
        fetchPolicy: forceNetwork ? 'network-only' : undefined,
      });
    },
    [logger, fetchRows, showDataOnFirstLoad, gridQueryVariables],
  );

  // Callback for the export button inside the DataGrid
  const exportData = useCallback<ExportDataCallback<any>>(
    async (settings) => {
      const {
        searchTerm: baseSearchTerm,
        filters: baseFilters,
        columns: baseColumns,
        sortBy: baseSortBy,
        visibleColumns: baseVisibleColumns,
        ids,
      } = settings;

      const queryVariables: GridQueryVariables = [
        ...(gridQueryVariables ?? []),
        {
          variableName: 'ids',
          type: '[String!]',
          value: ids,
          forField: 'exportUrl',
        },
      ];

      const query = makeConnectedGridExportQuery(queryName, queryVariables);

      // request the creation of an export file from the graphQL API
      let result = await apolloClient.query({
        context: { errorHandled: true },
        errorPolicy: 'all',
        query,
        variables: {
          searchTerm: baseSearchTerm,
          filters: convertFiltersForQuery(baseFilters, baseColumns),
          sorting: convertSortingForQuery(baseSortBy),
          columns: convertColumnsForQuery(
            shouldExportAllColumns ? baseColumns : baseVisibleColumns,
          ),
          ...constructGridQueryVariables(queryVariables),
        },
      });

      if (result.error || result.errors) {
        reportError('Grid Export failed, please try again later or contact us.', result.errors);
        return;
      }
      let exportedData: ConnectedGridExportQueryResult = extractGridFieldFromDataResult(
        result.data,
        queryName,
      );
      let { exportUrl } = exportedData;

      let { url, downloadable } = exportUrl;
      const { filename, exportId } = exportUrl;

      if (exportId === -1) {
        reportError(
          'Grid Export failed, please try again later or contact us.\nDetails:\nInvalid Export ID',
        );
        return;
      }

      // poll the client with the specified delay until the file is downloadable
      // or until the maximum amount of waiting time (15 minutes) has passed
      const pollingDelay = 2000; // ms
      const maxWait = 15 * 60 * 1000; // 15 minutes to milliseconds
      let retryCount = 0;

      /* eslint-disable no-await-in-loop */
      while (!downloadable && retryCount * pollingDelay < maxWait) {
        await sleep(pollingDelay);

        result = await apolloClient.query({
          query,
          variables: {
            exportId,
            ...constructGridQueryVariables(gridQueryVariables),
          },
          fetchPolicy: 'network-only',
        });

        exportedData = extractGridFieldFromDataResult(result.data, queryName);

        exportUrl = exportedData.exportUrl;
        url = exportUrl.url;
        downloadable = exportUrl.downloadable;
        retryCount += 1;

        if (result.error || result.errors) {
          reportError('Grid Export failed, please try again later or contact us.', result.errors);
          return;
        }
      }
      /* eslint-enable no-await-in-loop */

      // trigger download of export file
      if (url) {
        saveAs(url, `${filename}`);
      }
    },
    [apolloClient, queryName, gridQueryVariables, reportError, shouldExportAllColumns],
  );

  const handleSortingChange = useCallback(
    (rules) => {
      if (userSettings) {
        saveUserSettingsIfNotEqual({
          ...userSettings,
          sorting: convertSortingForQuery(rules),
        });
      }

      if (onSortingChange) {
        onSortingChange(rules);
      }
    },
    [onSortingChange, saveUserSettingsIfNotEqual, userSettings],
  );

  const handlePageSizeChange = useCallback(
    (pageSize) => {
      if (userSettings) {
        saveUserSettingsIfNotEqual({
          ...userSettings,
          pageSize,
        });
      }

      if (onPageSizeChange) {
        onPageSizeChange(pageSize);
      }
    },
    [onPageSizeChange, saveUserSettingsIfNotEqual, userSettings],
  );

  const handleColumnSizingChange = useCallback(
    (sizes: ColumnSizingState) => {
      if (userSettings) {
        saveUserSettingsIfNotEqual({
          ...userSettings,
          columns: userSettings.columns.map((column) => ({
            ...column,
            width:
              sizes[column.field] || // Changed width for field
              userSettings.columns.find((col) => col.field === column.field)?.width || // If missing, use current value
              DEFAULT_COLUMN_WIDTH, // Fallback to sane default
          })),
        });
      }

      if (onColumnSizingChange) {
        onColumnSizingChange(sizes);
      }
    },
    [onColumnSizingChange, saveUserSettingsIfNotEqual, userSettings],
  );

  const handleColumnOrderChange = useCallback(
    (columnOrder: ColumnOrderState) => {
      if (userSettings) {
        saveUserSettingsIfNotEqual({
          ...userSettings,
          columnOrder,
        });
      }

      if (onColumnOrderChange) {
        onColumnOrderChange(columnOrder);
      }
    },
    [onColumnOrderChange, saveUserSettingsIfNotEqual, userSettings],
  );

  const handleColumnVisibilityChange = useCallback(
    (visibilityState: VisibilityState) => {
      if (userSettings) {
        saveUserSettingsIfNotEqual({
          ...userSettings,
          columns: userSettings.columns.map((column) => ({
            ...column,
            hidden: !visibilityState[column.field],
          })),
        });
      }

      if (onColumnVisibilityChange) {
        onColumnVisibilityChange(visibilityState);
      }
    },
    [onColumnVisibilityChange, saveUserSettingsIfNotEqual, userSettings],
  );

  const checkableIds = useMemo(() => rowsData?.rows.checkableIds, [rowsData]);

  const totalRows = rowsData && showData ? rowsData.rows.totalCount : 0;

  const pageCount =
    userSettings && userSettings.pageSize !== 0 ? Math.ceil(totalRows / userSettings.pageSize) : 0;

  if (configLoading) {
    return <ContentLoading height={200} />;
  }

  if (columnsError || !gridId) {
    return <>Failed loading grid configuration</>;
  }

  const data = rowsData && showData ? [...rowsData.rows.nodes] : [];

  if (!columns || !columns.length) {
    return null;
  }

  return (
    <DataGrid
      {...gridProps}
      imperativeHandleRef={imperativeHandleRef}
      gridId={gridId}
      columns={columns}
      data={data}
      fetchData={fetchData}
      exportData={exportData}
      loading={rowsLoading || loading}
      totalItemCount={totalRows}
      initialColumnFilters={[...(staticFilters || []), ...(initialColumnFilters || [])]}
      initialSorting={initialSorting}
      initialPageSize={initialPageSize}
      initialSearchTerm={initialSearchTerm}
      pageCount={pageCount}
      maxPageSize={maxPageSize}
      localFiltersEnabled={localFiltersEnabled}
      showDataOnFirstLoad={showDataOnFirstLoad}
      onSortingChange={handleSortingChange}
      onColumnFiltersChange={onColumnFiltersChange}
      onPageSizeChange={handlePageSizeChange}
      onColumnSizingChange={handleColumnSizingChange}
      onColumnOrderChange={handleColumnOrderChange}
      onColumnVisibilityChange={handleColumnVisibilityChange}
      rowSelectEnabled={rowSelectEnabled}
      checkableIds={checkableIds}
    />
  );
}

const ConnectedDataGrid = memo(ConnectedDataGridComponent);
export default ConnectedDataGrid;
