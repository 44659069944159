import { useMemo, useState } from 'react';
import { useIntercom } from 'react-intercom-hook';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import Modal, { ModalButtonBar } from './Modal';
import { Row, Col } from '../layout/Grid';
import { useCreateOrUpdateUpsMerchantAccountMutation } from '../../operations/mutations/createOrUpdateUpsMerchant';
import { FakeLink, ExternalLink } from '../Link';
import PageSubtitle from '../layout/PageSubtitle';
import BillingAddressSubform from '../subforms/BillingAddressSubform';
import { billingAddressValidationSchema } from '../subforms/billingAddressSubformUtls';
import { useBillingAddressQuery } from '../../operations/queries/billingAddress';
import { useBillingAddressMutation } from '../../operations/mutations/updateBillingAddress';
import Loading from '../loading/PageLoading';
import Alert from '../Alert';
import { UpdateBillingAddressMutationVariables } from '../../gql/graphql';
import withIntercomLink from '../../utils/withIntercomLink';
import FormControl from '../form/FormControl';
import Checkbox from '../form/Checkbox';
import getPostcodeAndZip4 from '../../utils/getPostcodeAndZip4';

type UpsAccountModalProps = {
  open: boolean;
  buttonText: string;
  headline: string;
  onCancel: () => void;
  onAccountCreatedOrUpdated: () => void;
  showPickupText?: boolean;
};

function UpsAccountModal({
  open,
  buttonText,
  headline,
  onCancel,
  onAccountCreatedOrUpdated,
  showPickupText = true,
}: UpsAccountModalProps) {
  const [createOrUpdateUpsMerchantAccount, { loading: upsMerchantCreating, data: merchantData }] =
    useCreateOrUpdateUpsMerchantAccountMutation();
  const { show: showIntercomArticle } = useIntercom();
  const { data: billingAddressData, loading: billingAddressLoading } = useBillingAddressQuery();
  const [updateBillingAddress, { loading: billingAddressUpdating }] = useBillingAddressMutation();

  const handleSubmitCreateOrUpdateUpsMerchantAccount = async () => {
    const { data } = await createOrUpdateUpsMerchantAccount();
    if (data) {
      const {
        createOrUpdateUpsMerchantAccount: { status, error },
      } = data;
      if (!error && status === 'ok') {
        onAccountCreatedOrUpdated();
      }
    }
  };

  const [isAddressRetry, setIsAddressRetry] = useState(false);

  const handleSubmitBillingAddress = async ({
    billingAddress,
  }: UpdateBillingAddressMutationVariables) => {
    const { postcode, zip4 } = getPostcodeAndZip4(billingAddress.postcode);

    await updateBillingAddress({
      variables: {
        billingAddress: {
          fullName: billingAddress.fullName,
          company: billingAddress.company,
          address1: billingAddress.address1,
          address2: billingAddress.address2,
          postcode,
          zip4,
          regionCode: billingAddress.regionCode,
          countryCode: billingAddress.countryCode,
          city: billingAddress.city,
        },
      },
    });
    handleSubmitCreateOrUpdateUpsMerchantAccount();
    setIsAddressRetry(true);
  };

  const merchantCreationError = merchantData?.createOrUpdateUpsMerchantAccount.error;
  const errorCategory = merchantData?.createOrUpdateUpsMerchantAccount.errorCategory;
  const hasAddressError = errorCategory === 'address';

  /**
   * We want to show the address modal on every error that we did not identify as being an outage of the UPS account service
   * even if we are not sure it is an address error, because most of the time changing the address helps
   * When there was a retry in the billing address modal, we want to keep showing the billing address modal
   */
  const showBillingAddressForm =
    (merchantCreationError && errorCategory !== 'outage' && errorCategory !== 'maintenance') ||
    isAddressRetry;

  /**
   * On address errors, we only want to show the address modal and hide the error from the user to make it seem like everyting is ok
   * We show the error on retry to make it clear to the user that the chaneg address was not accepted and it is not just a broken
   * modal that does not do anything
   * If the error is not an address error, we want to show the error message, so it is clear that there could be something else
   * going wrong and it could be that changing the address does not help
   */
  const showAddressModalError = merchantCreationError && (isAddressRetry || !hasAddressError);

  const validationSchema = useMemo(() => billingAddressValidationSchema('ups'), []);

  const renderBillingAddressForm = () =>
    !billingAddressData ? (
      <Loading height="20vh" />
    ) : (
      <>
        {showAddressModalError && (
          <Alert variant="danger">
            {withIntercomLink(merchantCreationError, showIntercomArticle)}
          </Alert>
        )}
        <Formik
          enableReinitialize
          validationSchema={yup.object<any>({ billingAddress: validationSchema })}
          initialValues={{ billingAddress: billingAddressData.company.address }}
          onSubmit={handleSubmitBillingAddress}
          validateOnMount
        >
          <Form>
            <Row>
              <Col md={12}>
                UPS needs to confirm the physical address of your home, office, or warehouse.
              </Col>
              <Col md={12}>
                This will not change the Ship From Address that’s printed on the label.
              </Col>
              <Col md={12}>
                <PageSubtitle>Update your Physical Address and try again:</PageSubtitle>
              </Col>
              <Col md={12}>
                <BillingAddressSubform namespace="billingAddress" carrier="ups" />
              </Col>
              <Col md={12}>
                If you need any help just{' '}
                <FakeLink
                  onClick={() => {
                    showIntercomArticle();
                    onCancel();
                  }}
                >
                  chat with our support
                </FakeLink>
                !
              </Col>
              <Col md={12}>
                <ModalButtonBar
                  cancelText="Cancel & Choose a Different Carrier"
                  cancelOnClick={onCancel}
                  buttonText="Try again"
                  buttonVariant="success"
                  buttonType="submit"
                  buttonSize="large"
                  buttonProgress={billingAddressUpdating || upsMerchantCreating}
                  buttonDisabled={
                    billingAddressUpdating || billingAddressLoading || upsMerchantCreating
                  }
                />
              </Col>
            </Row>
          </Form>
        </Formik>
      </>
    );

  const renderAgreementContent = () => (
    <>
      {merchantCreationError && (
        <Alert variant="danger">
          {withIntercomLink(merchantCreationError, showIntercomArticle)}
        </Alert>
      )}
      <Row>
        {showPickupText && (
          <Col md={12} spaceBelow>
            You can schedule pickups on the <b>Ship</b> page or drop UPS packages off at over 85,000
            locations, including The UPS Store® and Drop Boxes, CVS Pharmacy, Michaels Stores,
            Advance Auto Parts, and local retailers that offer UPS services.
          </Col>
        )}
        <Col md={12} spaceBelow>
          To proceed, please accept the terms below.
        </Col>
        <Formik
          initialValues={{ agreed: false }}
          onSubmit={handleSubmitCreateOrUpdateUpsMerchantAccount}
          validationSchema={yup.object<{ agreed: boolean }>({
            agreed: yup.boolean().required().isTrue(),
          })}
        >
          <Form>
            <Col md={12}>
              <FormControl
                as={Checkbox}
                name="agreed"
                type="checkbox"
                label={
                  <>
                    By checking this box, I accept and agree to the
                    <ExternalLink href="https://www.ups.com/assets/resources/media/en_US/Ground-Saver.pdf">
                      {' '}
                      UPS Terms and Conditions{' '}
                    </ExternalLink>
                    and the
                    <ExternalLink href="https://www.ups.com/assets/resources/media/en_US/UTA.pdf">
                      {' '}
                      UPS Technology Agreement
                    </ExternalLink>
                    , and I will not ship any items in violation of the
                    <ExternalLink href="https://www.ups.com/us/en/support/shipping-support/shipping-special-care-regulated-items/prohibited-items.page">
                      {' '}
                      UPS List of Prohibited and Restricted Items for Shipping
                    </ExternalLink>
                    .
                  </>
                }
              />
            </Col>
            <Col md={12}>
              <ModalButtonBar
                buttonText={buttonText}
                buttonVariant="success"
                buttonSize="xLarge"
                buttonProgress={upsMerchantCreating}
                buttonDisabled={upsMerchantCreating}
                buttonType="submit"
              />
            </Col>
          </Form>
        </Formik>
      </Row>
    </>
  );

  return (
    <Modal
      width={800}
      open={open}
      onClose={onCancel}
      title={showBillingAddressForm ? 'UPS needs more information to continue' : headline}
    >
      {showBillingAddressForm ? renderBillingAddressForm() : renderAgreementContent()}
    </Modal>
  );
}
export default UpsAccountModal;
