import { MouseEvent, KeyboardEvent, useRef, useState } from 'react';
import { Row } from '@tanstack/react-table';
import ConnectedDataGrid from '../../dataGrid/ConnectedDataGrid';
import { Col } from '../../layout/Grid';
import ProgressButton from '../../form/ProgressButton';
import { DataGridImperativeHandleRef } from '../../dataGrid/DataGrid';

type OriginalRow = {
  id: string;
  title: string;
  transaction_type: string;
  payment_id: string;
  batch_id: string;
  shipment_id: string;
  reconciliation_run_id: string;
};

export default function TransactionsGrid() {
  const imperativeHandleRef = useRef<DataGridImperativeHandleRef>(null);
  const [isExporting, setIsExporting] = useState(false);

  const viewTransaction = async (row: Row<any>, event?: MouseEvent | KeyboardEvent) => {
    const original = row.original as OriginalRow;
    let url = '';

    switch (original.transaction_type) {
      case 'Label':
      case 'Labels':
        url = `${window.location.origin}/ship/batch?id=${original.batch_id}`;
        break;
      case 'Shipment':
        url = `${window.location.origin}/ship/shipment?id=${original.shipment_id}`;
        break;
      case 'Carrier Adjustment':
        url = `${window.location.origin}/reports/carrieradjustment?run=${original.reconciliation_run_id}`;
        break;
      case 'Payment':
      case 'Payment Refunded':
      case 'Fee':
        url = `${window.location.origin}/reports/receipt?id=${original.payment_id}`;
        break;
      default:
    }

    if (url) {
      // we need to use window for the bridged version to work correctly
      if (event && (event.ctrlKey || event.metaKey)) {
        // open in new tab
        window.open(url);
      } else {
        window.location.href = url;
      }
    }
  };

  return (
    <ConnectedDataGrid
      imperativeHandleRef={imperativeHandleRef}
      onRowClick={viewTransaction}
      queryName="transactionHistory"
      rightSideSlot={
        <Col xs={12} md={3} spaceBelow>
          <ProgressButton
            progress={isExporting}
            disabled={isExporting}
            fullWidth
            size="medium"
            variant="info"
            onClick={async () => {
              setIsExporting(true);
              await imperativeHandleRef.current?.exportData();
              setIsExporting(false);
            }}
          >
            Export
          </ProgressButton>
        </Col>
      }
    />
  );
}
