import Recaptcha from 'react-google-recaptcha';
import { Row, Col } from '../../../../layout/Grid';

type SignupCaptchaProps = {
  show: boolean;
  onChallengeSolved: (response: string) => void;
};

export default function SignupCaptcha({ show, onChallengeSolved }: SignupCaptchaProps) {
  if (!show) {
    return null;
  }

  return (
    <Row justify="center" align="center">
      <Col width="auto">
        <Recaptcha
          theme="light"
          size="normal"
          hl="en"
          sitekey={import.meta.env.REACT_APP_RECAPTCHA_PUBLIC_KEY}
          onChange={(response: string | null) => {
            if (response !== null && response !== '') {
              onChallengeSolved(response);
            }
          }}
        />
      </Col>
    </Row>
  );
}
