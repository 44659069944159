import { useState } from 'react';
import convertObjectToUrlSearchParams from '../utils/convertObjectToUrlSearchParams';
import { SignupFormValues } from '../components/pages/loggedout/signup/SignupFormValues';

type LegacySignupAddress = {
  full_name: string;
  company: string;
  address1: string;
  address2: string;
  postcode: string;
  city: string;
  region_id: string;
};

type LegacySignupPayload = {
  user: {
    email: string;
    password: string;
  };
  address: LegacySignupAddress & {
    phone: string;
  };
  use_address_as_return: boolean;
  return: LegacySignupAddress;
  cp: boolean;
  accepted_terms_of_service_actively: boolean;
  'g-recaptcha-response': string;
};

type LegacySignupResult = {
  redirectUrl?: string; // only set if not error occured
};

type UseLegacySignupTuple = [
  request: (
    values: SignupFormValues,
    pasted: boolean,
    captchaChallengeResponse?: string,
  ) => Promise<LegacySignupResult>,
  state: {
    loading: boolean;
    error?: string;
  },
];

export default function useLegacySignup(): UseLegacySignupTuple {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const withError = (errorCode: string) => {
    setLoading(false);
    setError(errorCode);

    return {};
  };

  const legacySignupRequest = async (
    {
      email,
      password,
      useOriginAsReturnAddress,
      originAddress,
      returnAddress,
      agree,
    }: SignupFormValues,
    pasted: boolean,
    captchaChallengeResponse?: string,
  ): Promise<LegacySignupResult> => {
    setError(undefined);
    setLoading(true);

    const payload: LegacySignupPayload = {
      user: {
        email,
        password,
      },
      address: {
        full_name: originAddress.fullName,
        company: originAddress.company,
        phone: originAddress.phone ?? '',
        address1: originAddress.address1,
        address2: originAddress.address2,
        postcode: originAddress.postcode,
        city: originAddress.city,
        region_id: originAddress.regionCode,
      },
      use_address_as_return: useOriginAsReturnAddress,
      return: {
        full_name: returnAddress.fullName,
        company: returnAddress.company,
        address1: returnAddress.address1,
        address2: returnAddress.address2,
        postcode: returnAddress.postcode,
        city: returnAddress.city,
        region_id: returnAddress.regionCode,
      },
      cp: pasted,
      accepted_terms_of_service_actively: agree,
      'g-recaptcha-response': captchaChallengeResponse ?? '',
    };

    const response = await fetch('/register/signup', {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-Requested-With': 'XMLHttpRequest', // make it an ajax request
      },
      body: convertObjectToUrlSearchParams(payload),
    });

    if (!response.ok) {
      return withError('response_not_ok');
    }

    const responseData = await response.json();
    if (!responseData || typeof responseData !== 'object') {
      return withError('no_response_data');
    }

    if (!Object.hasOwn(responseData, 'redirect')) {
      // @ts-ignore
      return withError(responseData.error ?? 'general_error');
    }

    setLoading(false);

    return {
      // @ts-ignore
      redirectUrl: responseData.redirect,
    };
  };

  return [
    legacySignupRequest,
    {
      loading,
      error,
    },
  ];
}
