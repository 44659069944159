import { ExternalLink } from './Link';
import ClipboardButton from './form/ClipboardButton';

type TrackingNumberProps = {
  value: string;
  url: string;
  toolTip?: boolean;
};

export default function TrackingNumber({ value, url, toolTip }: TrackingNumberProps) {
  return (
    <>
      <ExternalLink href={url}>{value}</ExternalLink>
      <ClipboardButton toolTip={toolTip} value={value} ariaTitle="tracking number" />
    </>
  );
}
