import { Dispatch, SetStateAction } from 'react';
import { FakeLink } from '../../Link';

type DetailToggleProps = {
  showPaymentDetails: boolean;
  setShowPaymentDetails: Dispatch<SetStateAction<boolean>>;
};

function DetailToggle({ showPaymentDetails, setShowPaymentDetails }: DetailToggleProps) {
  return (
    <FakeLink
      id="payment-details-toggle"
      onClick={() => setShowPaymentDetails(!showPaymentDetails)}
    >
      {showPaymentDetails ? <>Hide Payment Details</> : <>Show Payment Details</>}
      <input
        type="checkbox"
        checked={showPaymentDetails}
        onChange={() => setShowPaymentDetails(!showPaymentDetails)}
        name={showPaymentDetails ? 'Hide Payment Details' : 'Show Payment Details'}
        hidden
        style={{ position: 'absolute' }}
      />
    </FakeLink>
  );
}

export default DetailToggle;
