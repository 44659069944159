import { useState } from 'react';
import { Form, Formik } from 'formik';
import { ApolloError } from '@apollo/client';
import * as yup from 'yup';
import styled from '@emotion/styled';
import PageSubtitle from '../../layout/PageSubtitle';
import { Col, PageContainer, Row } from '../../layout/Grid';
import FormControl from '../../form/FormControl';
import Label from '../../form/Label';
import Select, { Option } from '../../form/Select';
import InputGroup from '../../form/InputGroup';
import TextField from '../../form/TextField';
import ProgressButton from '../../form/ProgressButton';
import formatCurrency from '../../../utils/formatCurrency';
import { billingPageQuery, useBillingPageQuery } from '../../../operations/queries/billingPage';
import { useAddCreditToPaymentSource } from '../../../operations/mutations/addCreditToPaymentSource';
import { addFlashMessage, setFlashMessage } from '../../../apollo/cache/flashMessages';
import PageLoading from '../../loading/PageLoading';
import PageHeader from '../../layout/PageHeader';
import usePlaid, { PlaidHookProps } from '../../../hooks/usePlaid';
import { TYPOGRAPHY } from '../../../styles/typography';
import { SPACING } from '../../../styles/spacing';
import { GREYSCALE } from '../../../styles/colors';
import useAriaAnnouncer from '../../../hooks/useAriaAnnouncer';
import environment from '../../../utils/environment';
import {
  PlaidAccessTokenProps,
  PlaidSuccessCallbackProps,
} from '../../../hooks/usePlaidAccessToken';
import { PlaidLinkTokenProps } from '../../../hooks/usePlaidLinkToken';

const Styled = {
  Col: styled(Col)`
    margin-block-start: calc(
      (${TYPOGRAPHY.body.fontSize} * ${TYPOGRAPHY.body.lineHeight}) + (2 * ${SPACING.sm})
    );
  `,
  CurrencyInput: styled(FormControl)`
    text-align: right;
  `,
  ShiftedSubheading: styled(PageSubtitle)`
    color: ${GREYSCALE.grey60};
    font-size: ${TYPOGRAPHY.fontSize.md};
    font-weight: normal;
    margin-top: -${SPACING.xl};
  `,
};

type CreditValues = {
  paymentSourceId: string;
  addCreditAmount: number | '';
};

function AddCreditPage() {
  const announce = useAriaAnnouncer();
  const { data, loading } = useBillingPageQuery();
  const [creditInProgress, setCreditInProgress] = useState<boolean>(false);
  const [addCreditToPaymentSource] = useAddCreditToPaymentSource();

  const [formValues, setFormValues] = useState<CreditValues>();

  const onPlaidSuccess = (payload: PlaidSuccessCallbackProps) => {
    if (payload?.successMessage) {
      setFlashMessage(payload.successMessage, 'success');
      announce(payload.successMessage, 'polite');
    }

    if (formValues) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      performAddCredit(formValues);
    }
  };

  const onPlaidError = (msg: string) => {
    setFlashMessage(msg, 'danger');
    announce(msg, 'assertive');
    setCreditInProgress(false);
  };

  const onPlaidEvent = (event: string) => {
    if (event === 'EXIT') {
      setCreditInProgress(false);
    }
  };

  const { getPlaidLinkToken } = usePlaid({
    onSuccessCallback: onPlaidSuccess,
    onErrorCallback: onPlaidError,
    onEventCallback: onPlaidEvent,
    forceOpen: true,
  } as PlaidAccessTokenProps & PlaidLinkTokenProps & PlaidHookProps);

  if (!data || loading)
    return (
      <PageContainer>
        <PageLoading />
      </PageContainer>
    );

  const { accountBalance } = data.company;
  const paymentSourceArray = data.company.paymentSources;
  // do not show Offline payment for add credit
  const accountPaymentSources: Option[] = data.company.paymentSources
    .filter((paymentSource) => paymentSource.paymentMethodType !== 'manual')
    .map(
      (paymentSource) =>
        ({
          value: paymentSource.id,
          title: paymentSource.title,
        } as Option),
    );

  const initialPaymentSourceId = (() => {
    if (accountPaymentSources.length > 1) {
      return data.company.settings.defaultPaymentSourceId;
    }
    if (accountPaymentSources.length === 1) {
      return String(accountPaymentSources[0].value);
    }
    return '';
  })();

  const initialValues: CreditValues = {
    paymentSourceId: initialPaymentSourceId,
    addCreditAmount: '',
  };

  const performAddCredit = async (values: CreditValues) => {
    setCreditInProgress(true);
    try {
      await addCreditToPaymentSource({
        variables: {
          paymentSourceId: values.paymentSourceId,
          addCreditAmount: Number(values.addCreditAmount),
        },
        refetchQueries: [billingPageQuery],
        awaitRefetchQueries: true,
        context: { errorHandled: true },
      });
      const message = `Y'ee successfully added an amount of ${formatCurrency(
        Number(values.addCreditAmount),
      )} from ${paymentSourceArray.find((source) => source.id === values.paymentSourceId)?.title}.`;
      addFlashMessage(message, 'success');
      announce(message, 'polite');
      setCreditInProgress(false);
    } catch (error) {
      if (error instanceof ApolloError) {
        error.graphQLErrors.forEach(({ extensions, message }) => {
          if (environment.isBridge() && extensions?.redirect) {
            window.location.reload();
            return;
          }

          if (extensions?.failureReason === 'PLAID_UNLINKED_ACCOUNT') {
            // I see no other way here
            setFormValues(values);

            getPlaidLinkToken('add_credit_page', values.paymentSourceId);
            return;
          }

          setFlashMessage(message, 'danger');
          announce(message, 'assertive');
        });
      }
    }
  };

  return (
    <PageContainer>
      <PageHeader title="Add Credit to your Pirate Ship account" />
      <Styled.ShiftedSubheading>
        Current balance: {formatCurrency(accountBalance)}
      </Styled.ShiftedSubheading>

      <Formik
        initialValues={initialValues}
        validationSchema={yup.object<CreditValues>({
          paymentSourceId: yup.string().required(),
          addCreditAmount: yup.number().min(1).max(99999).required(),
        })}
        onSubmit={async (values: CreditValues, { setFieldValue }) => {
          performAddCredit(values).then(() => {
            setFieldValue('addCreditAmount', '', false);
          });
        }}
      >
        <Form>
          <Row>
            <Col spaceBelow md={6} xs={12}>
              <Label>Payment Source</Label>
              <FormControl name="paymentSourceId" as={Select} options={accountPaymentSources} />
            </Col>
            <Col md={4} xs={12}>
              <Label secondary="- minimum $1.00">Amount</Label>
              <InputGroup prefixIcon="dollar-sign">
                <Styled.CurrencyInput
                  name="addCreditAmount"
                  as={TextField}
                  type="number"
                  placeholder="1.00"
                />
              </InputGroup>
            </Col>
            <Styled.Col md={2} xs={12}>
              <ProgressButton
                variant="primary"
                size="medium"
                type="submit"
                disabled={creditInProgress}
                progress={creditInProgress}
              >
                Add Credit
              </ProgressButton>
            </Styled.Col>
          </Row>
        </Form>
      </Formik>
    </PageContainer>
  );
}

export default AddCreditPage;
