import { useReactiveVar } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import tokenService from '../services/token';
import { currentUserVar, CurrentUser } from '../apollo/cache';
import { useUserLazyQuery } from '../operations/queries/user';
import { useLoginWithRememberTokenMutation } from '../operations/mutations/loginWithRememberToken';
import { useRememberLoginMutation } from '../operations/mutations/rememberLogin';
import setCurrentUser from '../operations/mutations/setCurrentUser';
import { useUserUnawareLogger } from './useLogger';
import environment from '../utils/environment';

type UseCurrentUserResult = [CurrentUser | null, { loading: boolean }];

// BRIDGE ONLY
declare global {
  interface Window {
    isLoggedIn?: boolean;
  }
}

let userLoadingMutex = false;
let loggingInWithRememberTokenMutex = false;

export default function useCurrentUser(): UseCurrentUserResult {
  const logger = useUserUnawareLogger();
  const navigate = useNavigate();
  const [checkComplete, setCheckComplete] = useState(false);
  const [
    loadUserData,
    {
      data: remoteUserData,
      error: remoteUserError,
      loading: remoteUserLoading,
      called: remoteUserCalled,
    },
  ] = useUserLazyQuery({
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
  });
  const [autoLogin, { loading: autoLoggingIn }] = useLoginWithRememberTokenMutation();
  const [rememberLogin, { loading: rememberingLogin }] = useRememberLoginMutation();
  const currentUser = useReactiveVar(currentUserVar);
  const loading = !checkComplete || remoteUserLoading || autoLoggingIn || rememberingLogin;

  // Load current user data
  useEffect(() => {
    if (currentUser || userLoadingMutex) {
      return;
    }

    const authToken = tokenService.getAuthToken();
    const rememberToken = tokenService.getRememberToken();

    if ((environment.isBridge() && window.isLoggedIn) || authToken != null) {
      logger.debug('Current user data unset, but authenticated. Loading user data.');

      userLoadingMutex = true;
      loadUserData().finally(() => {
        userLoadingMutex = false;
      });
    } else if (rememberToken == null) {
      setCheckComplete(true);
    }
  }, [logger, currentUser, navigate, loadUserData]);

  // Handle loaded user data
  useEffect(() => {
    if (!remoteUserCalled || remoteUserLoading) {
      return;
    }

    setCheckComplete(true);

    if (remoteUserData) {
      setCurrentUser(remoteUserData.user);
    }
  }, [remoteUserCalled, remoteUserLoading, remoteUserError, remoteUserData, navigate]);

  // Auto-login when remember token is set
  useEffect(() => {
    const authToken = tokenService.getAuthToken();
    const rememberToken = tokenService.getRememberToken();

    if (
      currentUser ||
      authToken != null ||
      rememberToken == null ||
      loggingInWithRememberTokenMutex
    ) {
      return;
    }

    loggingInWithRememberTokenMutex = true;
    autoLogin({
      variables: {
        rememberToken,
      },
    })
      .then((result) => {
        if (result.data) {
          tokenService.removeRememberToken(); // Token was consumed
          tokenService.setAuthToken(result.data.loginWithRememberToken.authToken);
          setCurrentUser(result.data.loginWithRememberToken.user);
        } else if (
          result.errors?.some((error) => error.extensions?.category === 'Authentication')
        ) {
          tokenService.removeRememberToken(); // Token was not valid
        }
      })
      .then(() => setCheckComplete(true))
      .then(() => rememberLogin())
      .then((result) => {
        if (result.data) {
          tokenService.setRememberToken(result.data.rememberLogin);
        }
      })
      .finally(() => {
        loggingInWithRememberTokenMutex = false;
      });
  }, [currentUser, autoLogin, rememberLogin]);

  return [currentUser, { loading }];
}
