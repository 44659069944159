import Chart from 'highcharts-react-official';
import * as Highcharts from 'highcharts';
import { GREYSCALE } from '../../styles/colors';
import { standardChartOptions } from '../../styles/charts';
import { ChartProps } from './types';

export type LineChartProps = Omit<ChartProps, 'colors'>;

export default function LineChart({ data, labels, customOptions }: LineChartProps) {
  const options: Highcharts.Options = Highcharts.merge(
    {
      chart: {
        height: 220,
      },
      xAxis: {
        categories: [...labels],
      },
      series: [
        {
          type: 'spline',
          data: [...data], // cannot accept readonly arrays
          showInLegend: false,
          lineWidth: 4,
          color: GREYSCALE.black,
          dashStyle: 'ShortDash',
          linecap: 'square',
          marker: {
            radius: 7,
            fillColor: GREYSCALE.black,
            lineWidth: 3,
            lineColor: GREYSCALE.white,
          },
          softThreshold: false,
        },
      ],
      tooltip: {
        formatter() {
          // eslint-disable-next-line react/no-this-in-sfc
          return `${this.x}: ${this.y}`;
        },
      },
    },
    standardChartOptions,
    customOptions,
  );
  return <Chart highcharts={Highcharts} options={options} />;
}
