import * as Highcharts from 'highcharts';
import { BORDER_RADIUS, BORDER_WIDTH } from './borders';
import { GREYSCALE } from './colors';
import { SPACING } from './spacing';
import { TYPOGRAPHY } from './typography';

// styling used in every chart
const commonChartOptions: Highcharts.Options = {
  chart: {
    backgroundColor: 'transparent',
    style: {
      fontFamily: 'ARRrrrial,ARRrrrmoji,sans-serif',
    },
  },
  title: {
    text: undefined,
  },
  tooltip: {
    backgroundColor: GREYSCALE.black,
    borderRadius: parseInt(BORDER_RADIUS.lg),
    borderColor: GREYSCALE.black,
    padding: parseInt(SPACING.md),
    style: {
      color: GREYSCALE.white,
      fontSize: TYPOGRAPHY.fontSize.md,
    },
  },
  credits: {
    enabled: false,
  },
} as const;

// styling used in every non-map chart
export const standardChartOptions: Highcharts.Options = Highcharts.merge(
  {
    yAxis: {
      title: {
        text: undefined,
      },
      labels: {
        enabled: false,
      },
      gridLineWidth: parseInt(BORDER_WIDTH.none),
      startOnTick: false,
      endOnTick: false,
    },
    xAxis: {
      labels: {
        style: {
          color: GREYSCALE.black,
          fontSize: '1.2em',
          fontWeight: '600',
        },
      },
      lineWidth: 0,
    },
  },
  commonChartOptions,
);

// styling used in every map chart
export const standardMapChartOptions: Highcharts.Options = Highcharts.merge(
  {
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        align: 'right',
      },
      enableMouseWheelZoom: false,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      formatter() {
        return `${this.point.name === 'Turkey' ? 'Türkiye' : this.point.name}: ${
          this.point.value
        }%`;
      },
    },
    colorAxis: {
      min: 0,
      stops: [
        [0, '#d8d8d8'],
        [0.01, '#d9e0d2'],
        [1, '#436721'],
      ],
    },
  },
  commonChartOptions,
);
