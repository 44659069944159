import { QueryHookOptions, useLazyQuery, useQuery } from '@apollo/client';
import {
  DownloadLabelsByShipmentsQuery,
  DownloadLabelsByShipmentsQueryVariables,
} from '../../gql/graphql';
import { gql } from '../../gql';

export const downloadLabelsByShipmentsQuery = gql(/* GraphQL */ `
  query DownloadLabelsByShipmentsQuery($shipmentIds: [ID!]!, $pageLayout: PageLayout) {
    downloadLabelsByShipments(shipmentIds: $shipmentIds, pageLayout: $pageLayout) {
      id
      status
      fileFormat
      labelSize
      pageLayout
      url
      shipments {
        id
        canInstantRefundShipment
        printed
        canPrint
        isShipmentRefundable
      }
    }
  }
`);

export const useDownloadLabelsByShipmentsQuery = (
  options: QueryHookOptions<
    DownloadLabelsByShipmentsQuery,
    DownloadLabelsByShipmentsQueryVariables
  >,
) => useQuery(downloadLabelsByShipmentsQuery, options);

export const useDownloadLabelsByShipmentsLazyQuery = (
  options: QueryHookOptions<
    DownloadLabelsByShipmentsQuery,
    DownloadLabelsByShipmentsQueryVariables
  >,
) => useLazyQuery(downloadLabelsByShipmentsQuery, options);
