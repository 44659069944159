import { QueryHookOptions, useQuery } from '@apollo/client';
import { gql } from '../../gql';
import { BatchQuery, BatchQueryVariables } from '../../gql/graphql';

export const batchQuery = gql(/* GraphQL */ `
  query BatchQuery($id: ID!) {
    batch(id: $id) {
      id
      title
      status
      stepText
      ...RunningProcessFragment
      shipDatePossibleNow
      warehouse {
        id
        title
        shy
        originAddress {
          id
          ...AddressFragment
        }
        returnAddress {
          id
          ...AddressFragment
        }
      }
      numShipments
      shipDate
      dataSource
      labelFileFormat
      labelSize
      spreadsheetMapping {
        id
        shipmentKey
        valueOrMappingKey
      }
      packageSummary {
        maxWeight
        minWeight
        maxInsured
        minInsured
        dimensionsSummary
        packageTypeSummary
        deliveryConfirmationSummary
        packagePreset {
          ...PackageFragment
        }
      }
      customsFormSummary {
        numCustomsForms
        signer
        title
        contentType
        itemSummary
        exporterTaxIdSummary
      }
      shipmentStatusSummary {
        ...ShipmentStatusSummaryFragment
      }
      rubberStamps {
        shipmentKey
        valueOrMappingKey
        userFriendlyShipmentKey
      }
      emailNotificationPossible
      shipments(limit: 1, offset: 0) {
        id
        order {
          id
          foreignOrderId
          platformKey
          orderLink {
            text
            url
          }
          shipmentLink {
            url
            text
          }
          subscriptionLink {
            url
            text
          }
          platform {
            title
          }
          orderItems {
            quantity
            title
            sku
          }
        }
        isReturnLabel
        recipientAddress {
          ...AddressFragment
          address3
          email
          phone
        }
      }
      rateGroups {
        title
        maximumShipments
        numberOfCheapestOrBestServices
        defaultShipDate
        groupKey {
          string
          traits {
            layer
            value
          }
        }
        intercomLink
        affectedByUpsRateLimit
        rateSummaries {
          ...RateSummaryFragment
          availableShipDates
          upsellRateSummary {
            carrier {
              carrierKey
              title
            }
            firstMailClass {
              mailClassKey
            }
            mailClassTitle
            totalPrice
            uniqueId
          }
        }
      }
    }
    user {
      id
      rateGroupSortOrder
      email
      timezone
    }
    company {
      id
      activeCarriers
      accountBalance
      features {
        key
        value
      }
      settings {
        shipmentLabelSize
        shipmentLabelFileFormat
        packingListTemplate
        shipmentSpreadsheetFormat
        addressCorrectionEnabled
        defaultTrackingEmailsDelay
        defaultTrackingEmailsEnabled
        defaultEmailTemplateId
        defaultChargeAmount
        defaultPaymentSourceId
      }
      paymentSources {
        ...PaymentSourceFragment
      }
      hasAnyPlaidPaymentSource
      createdMerchantAccounts
      hasUspsMerchantAccountError
      uspsMerchantAccountStatus
      upsMerchantAccountStatus
      activePaymentMethods
      mailTemplates {
        id
        subject
        name
        senderEmail
        senderName
        asDefault
      }
    }
    paymentApiMethods {
      ...PaymentApiMethodFragment
    }
  }
`);

export const useBatchQuery = (options?: QueryHookOptions<BatchQuery, BatchQueryVariables>) =>
  useQuery(batchQuery, options);
