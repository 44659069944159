import SingleUpLeft from './single-up-left.svg';
import SingleUpRight from './single-up-right.svg';
import DoubleUp from './double-up.svg';
import FourSix from './four-six.svg';
import TwoSeven from './two-seven.svg';

const printerTypeIcons = {
  SingleUpLeft,
  SingleUpRight,
  DoubleUp,
  FourSix,
  TwoSeven,
};

export default printerTypeIcons;
