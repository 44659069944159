import styled from '@emotion/styled';
import { BORDER_WIDTH, BORDER_RADIUS } from '../../styles/borders';
import { GREYSCALE, COLOR, PirateShipColor } from '../../styles/colors';
import { SPACING } from '../../styles/spacing';
import { TYPOGRAPHY } from '../../styles/typography';

export default {
  Content: styled.div`
    border: ${BORDER_WIDTH.sm} solid ${GREYSCALE.grey30};
    border-radius: ${BORDER_RADIUS.sm};
    background-color: ${GREYSCALE.grey10};
    font-size: ${TYPOGRAPHY.fontSize.sm};
    color: ${GREYSCALE.grey60};
  `,
  Row: styled.div<{ white?: boolean; color?: PirateShipColor }>`
    display: flex;
    padding: ${SPACING.md};
    flex-direction: row;
    justify-content: space-between;
    border-color: ${GREYSCALE.grey30};
    border-style: solid;
    border-width: ${BORDER_WIDTH.none} ${BORDER_WIDTH.none} ${BORDER_WIDTH.xs} ${BORDER_WIDTH.none};
    &:last-of-type {
      border-bottom-width: ${BORDER_WIDTH.none};
    }
    color: ${({ color }) => color ?? 'inherit'};
    background-color: ${({ white }) => (white ? GREYSCALE.white : 'auto')};
  `,
  Col: styled.div<{ alignRight?: boolean }>`
    text-align: ${({ alignRight }) => (alignRight ? 'right' : 'left')};
  `,
  Price: styled.span<{ red?: boolean }>`
    color: ${({ red }) => (red ? COLOR.red : 'inherit')};
  `,
  SubText: styled.small`
    color: ${GREYSCALE.grey50};
    padding-bottom: ${SPACING.md};
  `,
  Strikethrough: styled.del`
    color: ${COLOR.red};
  `,
  SurchargeTitle: styled.span<{ color: string }>`
    color: ${({ color }) => color};
  `,
  AdjustmentNote: styled.div`
    color: ${COLOR.red};
  `,
  Header: styled.div`
    font-weight: ${TYPOGRAPHY.fontWeight.medium};
  `,
  Spacer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${SPACING.md};
  `,
  ServiceTitleWrapper: styled.div`
    display: flex;
    gap: ${SPACING.sm};
  `,
};
