import type { CellContext, Row } from '@tanstack/react-table';
import TrackingNumber from '../../TrackingNumber';

export default function TrackingNumberCell<TData, TValue extends unknown>({
  getValue,
  row,
}: CellContext<TData, TValue>) {
  const value = getValue() as string;
  const { original }: Row<TData> = row;
  const { tracking_nr_url: url } = original as { tracking_nr_url: string }; // the nodes delivered by the grid query must contain a tracking_nr_url

  if (!value || !url) return '';

  return <TrackingNumber toolTip={false} value={value} url={url} />;
}
