import { useMutation } from '@apollo/client';
import { gql } from '../../gql';
import { CarrierKey } from '../../gql/graphql';

export const createOrUpdateUpsMerchantAccountMutation = gql(/* GraphQL */ `
  mutation CreateOrUpdateUpsMerchantAccountMutation {
    createOrUpdateUpsMerchantAccount {
      status
      error
      errorCategory
    }
  }
`);

export const useCreateOrUpdateUpsMerchantAccountMutation = () =>
  useMutation(createOrUpdateUpsMerchantAccountMutation, {
    // Add UPS to the list of created merchant accounts after creation
    update: (cache, { data }) => {
      if (!data) {
        return;
      }

      cache.updateQuery(
        {
          query: gql(/* GraphQL */ `
            query AddUpsToCreatedMerchantAccountsAfterCreateQuery {
              company {
                id
                createdMerchantAccounts
              }
            }
          `),
        },
        (cacheResult) => {
          if (!cacheResult) {
            return undefined;
          }

          // add UPS to array of carrierKeys
          const newCreatedMerchantAccounts: CarrierKey[] =
            !data.createOrUpdateUpsMerchantAccount.error &&
            data.createOrUpdateUpsMerchantAccount.status === 'ok'
              ? Array.from(new Set(cacheResult.company.createdMerchantAccounts).add('ups'))
              : [...cacheResult.company.createdMerchantAccounts];

          return {
            company: {
              ...cacheResult.company,
              createdMerchantAccounts: newCreatedMerchantAccounts,
            },
          };
        },
      );
    },
  });
