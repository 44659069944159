import NumberFormat from 'react-number-format';

type Distribution = {
  key: string;
  count: string;
  name: string;
  percent: number;
  price: number;
};

type ReportTop5Props = {
  distributions?: readonly Distribution[];
};

function ReportTop5({ distributions }: ReportTop5Props) {
  const renderTop5Distributions = () => {
    const top5 = [];

    if (distributions) {
      for (let index = 0; index < 5 && index <= distributions.length - 1; index++) {
        const { name, count, percent, price } = distributions[index];
        top5.push(
          <li key={index}>
            <strong data-testid={`reports-top5-label-${index}`}>{name}</strong>
            {' - '}
            <NumberFormat suffix="%" displayType="text" value={percent} /> {' - '}
            <NumberFormat suffix=" shipments" displayType="text" value={count} />
            {' - '}
            <NumberFormat
              prefix="$"
              suffix=" avg"
              decimalScale={2}
              fixedDecimalScale
              displayType="text"
              value={price}
              data-testid={`reports-top5-value-${index}`}
            />
          </li>,
        );
      }
    }
    return top5;
  };

  return <ol>{renderTop5Distributions()}</ol>;
}

export default ReportTop5;
