import { useMutation } from '@apollo/client';
import { gql } from '../../gql';
import { CarrierKey } from '../../gql/graphql';

export const createUspsMerchantAccountMutation = gql(/* GraphQL */ `
  mutation CreateUspsMerchantAccountMutation {
    createUspsMerchantAccount {
      status
      error
      errorCategory
    }
  }
`);

export const useCreateUspsMerchantAccountMutation = () =>
  useMutation(createUspsMerchantAccountMutation, {
    // Add UsPS to the list of created merchant accounts after creation
    update: (cache, { data }) => {
      if (!data) {
        return;
      }

      cache.updateQuery(
        {
          query: gql(/* GraphQL */ `
            query AddUspsToCreatedMerchantAccountsAfterCreateQuery {
              company {
                id
                createdMerchantAccounts
              }
            }
          `),
        },
        (cacheResult) => {
          if (!cacheResult) {
            return undefined;
          }

          // add USPS to array of carrierKeys if there was no error
          const newCreatedMerchantAccounts: CarrierKey[] =
            !data.createUspsMerchantAccount.error && data.createUspsMerchantAccount.status === 'ok'
              ? Array.from(new Set(cacheResult.company.createdMerchantAccounts).add('usps'))
              : [...cacheResult.company.createdMerchantAccounts];

          return {
            company: {
              ...cacheResult.company,
              uspsMerchantAccountStatus: data.createUspsMerchantAccount.status, // update status of usps account
              createdMerchantAccounts: newCreatedMerchantAccounts,
              hasUspsMerchantAccountError: data.createUspsMerchantAccount.error,
            },
          };
        },
      );
    },
  });
